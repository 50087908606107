import {textColor} from "../../views/projects/project-overview/components/utilities/variables";
import {Grid, Text} from "@chakra-ui/react";
import React from "react";

export const TableHeader = ({headerData}) => {
  const thFontSize = "10px";
  return (
    <Grid
      templateColumns="repeat(12, 1fr)"
      gap={1}
      color={textColor}
      justifyItems={"center"}
    >
      {headerData.map((item, index) => {
        return (
          <Text
            fontSize={thFontSize}
            fontFamily="Plus Jakarta Display"
            borderColor="#56577A"
            fontWeight={"bold"}
          >
            {`${item.text} ${item.uom}`}
          </Text>
        )
      })
      }
    </Grid>
  )
}
