import {Grid, GridItem, Text} from "@chakra-ui/react";

const Brand = ({color1, color2}) => {
  return (
    <>
      <Text
        fontWeight={"bold"}
        align={"right"}
        color={color1}
        as={"h1"}
        fontSize={"6xl"}
        textShadow="1px 1px 2px gray"
      >
        HMS |
      </Text>
      <GridItem display="flex" flexDirection="column" justifyContent={"center"}>
        <Text
          fontWeight={"bold"}
          fontSize={"xl"}
          color={color2}
          align={"left"}
          textShadow="1px 1px 2px gray"
        >
          MACHINING &
        </Text>
        <Text
          fontWeight={"bold"}
          fontSize={"xl"}
          color={color2}
          align={"left"}
          textShadow="1px 1px 2px gray"
        >
          SOLUTIONS
        </Text>
      </GridItem>
    </>
  )
}

export default Brand