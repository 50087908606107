import {Document, Image, Page, Text, View} from "@react-pdf/renderer";
import PdfHeader from "../PdfHeader";
import usePdfStyles from "../../hooks/usePdfstyles";
import React from "react";
import mvpsLogo from "../../../../../variables/s3-images";

const PdfBase = ({
  data,
  children,
  name,
  image = false,
  table = false,
  headers = false,
  renderRows = false,
  orientation = "landscape"
}) => {
  const styles = usePdfStyles();

  return (
    <Document
      pageLayout={"twoColumnLeft"}
      creator={"MVPS"}
      subject={`${name} ${data?.header?.productName}`}
      title={`${name} ${data?.header?.productName}`}
      author={"https://molding-vps.com"}
    >
      <Page bookmark={"MVPS"} size="LETTER">
        <PdfHeader data={data} title={name} />
        {children}
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) => (
            <>
              <Text style={styles.pageNumber}>https://molding-vps.com</Text>
            </>
          )}
          fixed
        />
      </Page>
      {data?.table && table && (
        <Page size="LETTER" orientation={orientation} style={styles.page}>
          <View
            fixed
            style={{
              position: "absolute",
              minWidth: "50%",
              alignSelf: "center",
              justifyContent: "center",
              top: "40%",
              display: "block",
              width: "50%",
              opacity: 0.05,
            }}
          >
            <Image src={mvpsLogo} />
          </View>

          <Image
            src={mvpsLogo}
            style={{
              marginTop: "20px",
              alignSelf: "center",
              width: "150px",
              justifyContent: "center",
              display: "block",
              marginBottom: "20px",
            }}
          />
          <Text style={styles.header}>Resultados del Estudio</Text>
          <View style={styles.table}>
            {/* Encabezados */}
            <View style={styles.tableHeader}>
              {headers.map((header, index) => (
                <Text key={index} style={[styles.cell, styles.headerCell]}>
                  {header}
                </Text>
              ))}
            </View>
            {/* Filas */}
            {renderRows()}
          </View>
          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) => (
              <>
                <Text style={styles.pageNumber}>https://molding-vps.com</Text>
              </>
            )}
            fixed
          />
        </Page>
      )}
    </Document>
  );
};

export default PdfBase;
