import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { DataStore } from "aws-amplify/datastore";
import {LinearityStudy} from "../../../../../../../models";
import { Image, PDFViewer, Text, View } from "@react-pdf/renderer";
import "moment/locale/es";
import PdfBase from "../../../../components/Pdf/PdfBase";
import PdfEntries from "../../../../components/Pdf/PdfEntries";

import { dataEntries1, dataEntries2 } from "./utilities/dataEntries";
import { dataOutputs1, dataOutputs2 } from "./utilities/dataOutputs";
import { useGetUrl } from "../../../../hooks/useGetUrl";
import usePdfStyles from "../../../../hooks/usePdfstyles";
import mvpsLogo from "../../../../../../../variables/s3-images";
const IndividualLinearityStudyPdf = () => {
  const { id } = useParams();

  const [data, setData] = useState(null);
  const [href, setHref] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const responseData = await DataStore.query(LinearityStudy, id);
      setData(responseData);
    };
    fetchData();
  }, [id]);

  useEffect(() => {
    useGetUrl(data, setHref);
  }, [data]);

  const styles = usePdfStyles();
  const parseSeparated = (number) => {
    const numeroRedondeado = Math.round(number);

    // Usamos toLocaleString para formatear el número con comas
    return numeroRedondeado.toLocaleString("en-US");
  };

  const headers = [
    "SECUENCIA",
    "VELOCIDAD",
    "TRANSFERENCIA V/P",
    "TIEMPO DE LLENADO",
    "MAX PRESS",
    "PESO DE CAVIDADES",
    "VELOCIDAD REAL",
    "VISCOSIDAD RELATIVA",
    "MAXIMA PRESION (condicional)",
    "REOLOGIA (condicional)",
    "LINEALIDAD (condicional)",
    "MAX SHARE RATE (condicional)",
  ];

  const renderRows = () =>
    data?.table?.map((row, index) => (
      <View style={styles.tableRow} key={index}>
        <Text style={styles.cell}>{row.sequence}</Text>
        <Text style={styles.cell}>{parseFloat(row.speed).toFixed(3)}</Text>
        <Text style={styles.cell}>{row.vpTransfer}</Text>
        <Text style={styles.cell}>{row.fillTime}</Text>
        <Text style={styles.cell}>{parseSeparated(row.maxPress)}</Text>
        <Text style={styles.cell}>{row.cavityWeight}</Text>
        <Text style={styles.cell}>{(row.realSpeed)}</Text>
        <Text style={styles.cell}>{parseSeparated(row.realViscosity)}</Text>
        <Text style={styles.cell}>{parseSeparated(row.maxPress2)}</Text>
        <Text style={styles.cell}>{parseFloat(row.reologhy).toFixed(3)}</Text>
        <Text style={styles.cell}>{parseFloat(row.linearity).toFixed(3)}</Text>
        <Text style={styles.cell}>{parseSeparated(row.maxShearRate)}</Text>
      </View>
    ));

  const MyDocument = ({ data, chartImageUri }) => (
    <>
      <PdfBase
        data={data}
        name={"Estudio de Linealidad y Reología"}
        image={chartImageUri}
        table={true}
        headers={headers}
        renderRows={renderRows}
      >
        <PdfEntries
          title={"Entradas"}
          column1={dataEntries1(data)}
          column2={dataEntries2(data)}
        />

        <PdfEntries
          title={"Salidas"}
          column1={dataOutputs1(data)}
          column2={dataOutputs2(data)}
        />
        <Text style={[styles.header, { marginTop: 210 }]}>{"Graficas"}</Text>
        <Image
          src={mvpsLogo}
          style={{
            marginTop: "20px",
            alignSelf: "center",
            width: "150px",
            justifyContent: "center",
            display: "block",
            marginBottom: "20px",
          }}
        />

        <View
          style={{
            justifyContent: "center",
            border: 1,
            width: "90%",
            padding: "10px",
            marginLeft: "5%",
            marginRight: "5%",
            borderRadius: 10,
            flexDirection: "row",
          }}
        >
          <Image src={href} style={styles.image} />
        </View>
      </PdfBase>
    </>
  );

  return (
    <>
      <PDFViewer showToolbar={true} style={{ width: "100%", height: "90vh" }}>
        <MyDocument data={data} />
      </PDFViewer>
    </>
  );
};

export default IndividualLinearityStudyPdf;
