import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { DataStore } from "aws-amplify/datastore";
import { ViscosityStudy } from "../../../../../../../models";
import { Image, Page, PDFViewer, Text, View } from "@react-pdf/renderer";
import "moment/locale/es";
import PdfBase from "../../../../components/Pdf/PdfBase";
import PdfEntries from "../../../../components/Pdf/PdfEntries";

import { dataEntries1, dataEntries2 } from "./utilities/dataEntries";
import { dataOutputs1, dataOutputs2 } from "./utilities/dataOutputs";
import { useGetUrl } from "../../../../hooks/useGetUrl";
import usePdfStyles from "../../../../hooks/usePdfstyles";
const IndividualViscosityStudyPdf = () => {
  const { id } = useParams();

  const [data, setData] = useState(null);
  const [href, setHref] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const responseData = await DataStore.query(ViscosityStudy, id);
      setData(responseData);
    };
    fetchData();
  }, [id]);

  useEffect(() => {
    useGetUrl(data, setHref);
  }, [data]);
  console.log(data);

  const styles = usePdfStyles();
  const parseSeparated = (number) => {
    const numeroRedondeado = Math.round(number);

    // Usamos toLocaleString para formatear el número con comas
    return numeroRedondeado.toLocaleString("en-US");
  };

  const headers = [
    "SECUENCIA DE DISPARO",
    "VELOCIDAD DE INTERVALOS (in/seg)",
    "TIEMPO DE LLENADO (seg)",
    "PRESION MAXIMA (Hpsi)",
    "PESO DE DISPARO (Oz)",
    "POSICION DE CORTE V/P (in)",
    "PRESION MAXIMA (Ppsi)",
    "FLOW RATE (in³)",
    "VISCOSIDAD REAL DE MAQUINA (in/seg)",
    "EFICIENCIA DE VELOCIDAD (%)",
    "SHARE RATE (1/seg)",
    "VISCOSIDAD RELATIVA",
  ];

  const renderRows = () =>
    data?.table?.map((row, index) => (
      <View style={styles.tableRow} key={index}>
        <Text style={styles.cell}>{row.sequence}</Text>
        <Text style={styles.cell}>{parseFloat(row.speed).toFixed(3)}</Text>
        <Text style={styles.cell}>{row.fillTime}</Text>
        <Text style={styles.cell}>{row.maxPress}</Text>
        <Text style={styles.cell}>{row.shotWeight}</Text>
        <Text style={styles.cell}>{row.vpTransfer}</Text>
        <Text style={styles.cell}>{parseSeparated(row.maxPressPpsi)}</Text>
        <Text style={styles.cell}>{parseFloat(row.flowRate).toFixed(3)}</Text>
        <Text style={styles.cell}>{parseFloat(row.realSpeed).toFixed(3)}</Text>
        <Text style={styles.cell}>{parseFloat(row.efficence).toFixed(3)}</Text>
        <Text style={styles.cell}>{parseFloat(row.shearRate).toFixed(3)}</Text>
        <Text style={styles.cell}>{parseFloat(row.relVisc).toFixed(3)}</Text>
      </View>
    ));

  const MyDocument = ({ data, chartImageUri }) => (
    <>
      <PdfBase
        data={data}
        name={"Estudio de Viscosidad"}
        image={chartImageUri}
        table={true}
        headers={headers}
        renderRows={renderRows}
      >
        <PdfEntries
          title={"Entradas"}
          column1={dataEntries1(data)}
          column2={dataEntries2(data)}
        />

        <PdfEntries
          title={"Salidas"}
          column1={dataOutputs1(data)}
          column2={dataOutputs2(data)}
        />
        <Text style={[styles.header, { marginTop: 250 }]}>{"Graficas"}</Text>
        <View
          style={{
            justifyContent: "center",
            border: 1,
            width: "90%",
            padding: "10px",
            marginLeft: "5%",
            marginRight: "5%",
            borderRadius: 10,
            flexDirection: "row",
          }}
        >
          <Image src={href} style={styles.image} />
        </View>
      </PdfBase>
    </>
  );

  return (
    <>
      <PDFViewer showToolbar={true} style={{ width: "100%", height: "90vh" }}>
        <MyDocument data={data} />
      </PDFViewer>
    </>
  );
};

export default IndividualViscosityStudyPdf;
