/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cloud_logic_custom": [
        {
            "name": "cavityBalance",
            "endpoint": "https://fqtz5yn88c.execute-api.us-east-2.amazonaws.com/master",
            "region": "us-east-2"
        },
        {
            "name": "chargeFunction",
            "endpoint": "https://j9ca5b4uaa.execute-api.us-east-2.amazonaws.com/master",
            "region": "us-east-2"
        },
        {
            "name": "coolingTime",
            "endpoint": "https://3f16cpwn39.execute-api.us-east-2.amazonaws.com/master",
            "region": "us-east-2"
        },
        {
            "name": "dryerSupply",
            "endpoint": "https://3xfzqw25s5.execute-api.us-east-2.amazonaws.com/master",
            "region": "us-east-2"
        },
        {
            "name": "enduranceTime",
            "endpoint": "https://knqwr5xka3.execute-api.us-east-2.amazonaws.com/master",
            "region": "us-east-2"
        },
        {
            "name": "gateSealing",
            "endpoint": "https://674gcw0ip4.execute-api.us-east-2.amazonaws.com/master",
            "region": "us-east-2"
        },
        {
            "name": "geaTool",
            "endpoint": "https://ystp4bf77l.execute-api.us-east-2.amazonaws.com/master",
            "region": "us-east-2"
        },
        {
            "name": "hooperCapacity",
            "endpoint": "https://63vw5rdli4.execute-api.us-east-2.amazonaws.com/master",
            "region": "us-east-2"
        },
        {
            "name": "intensificationRatio",
            "endpoint": "https://n638fi7oh5.execute-api.us-east-2.amazonaws.com/master",
            "region": "us-east-2"
        },
        {
            "name": "intensificationRatioInd",
            "endpoint": "https://fkaw0jnooi.execute-api.us-east-2.amazonaws.com/master",
            "region": "us-east-2"
        },
        {
            "name": "intensificationRatioNew",
            "endpoint": "https://c64ewx961e.execute-api.us-east-2.amazonaws.com/master",
            "region": "us-east-2"
        },
        {
            "name": "linearityStudy",
            "endpoint": "https://tgbklez9jb.execute-api.us-east-2.amazonaws.com/master",
            "region": "us-east-2"
        },
        {
            "name": "paySubscription",
            "endpoint": "https://ny23uubld8.execute-api.us-east-2.amazonaws.com/master",
            "region": "us-east-2"
        },
        {
            "name": "pressureLoss",
            "endpoint": "https://7zmtw5kxrd.execute-api.us-east-2.amazonaws.com/master",
            "region": "us-east-2"
        },
        {
            "name": "projectedAreaNew",
            "endpoint": "https://jgcrspheh3.execute-api.us-east-2.amazonaws.com/master",
            "region": "us-east-2"
        },
        {
            "name": "sendEmailCollaborators",
            "endpoint": "https://ezgr4z5whg.execute-api.us-east-2.amazonaws.com/master",
            "region": "us-east-2"
        },
        {
            "name": "shotSize",
            "endpoint": "https://ompvkqrjra.execute-api.us-east-2.amazonaws.com/master",
            "region": "us-east-2"
        },
        {
            "name": "sustainingPressure",
            "endpoint": "https://vd4eknuce7.execute-api.us-east-2.amazonaws.com/master",
            "region": "us-east-2"
        },
        {
            "name": "vcheckRepeatability",
            "endpoint": "https://td4ksl4hwi.execute-api.us-east-2.amazonaws.com/master",
            "region": "us-east-2"
        },
        {
            "name": "viscoscityStudy",
            "endpoint": "https://k49uxhnlca.execute-api.us-east-2.amazonaws.com/master",
            "region": "us-east-2"
        },
        {
            "name": "windowProcess",
            "endpoint": "https://g7mvbk0tr4.execute-api.us-east-2.amazonaws.com/master",
            "region": "us-east-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://rtzpue7ajjdehg3x5ra2xoaig4.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-fw5rbpt7djeuhf4wy5gsfadiwm",
    "aws_cognito_identity_pool_id": "us-east-2:552f3d9f-f709-4d40-985c-ffc6b231758d",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_BanYXr7aB",
    "aws_user_pools_web_client_id": "2deq36303tdq0es79a8sta7vfr",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "individual-images80305-master",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
