import { HomeIcon } from "components/Icons/Icons";

import Gea from "./views/collaborators/gea/Gea";
import Gintec from "./views/collaborators/gintec/Gintec";
import TecnicasPlasticas from "./views/collaborators/tecnicas-plasticas/TecnicasPlasticas";
import Rediassa from "./views/collaborators/rediasa/Rediassa";
import AsaClean from "./views/collaborators/asaclean/AsaClean";
import MyProfile from "./views/users/profile/MyProfile";
import MyMolds from "./views/users/molds/MyMolds";
import MyMachines from "./views/users/machines/MyMachines";
import MyResines from "./views/users/resines/MyResines";
import MyProyects from "./views/projects/MyProyects";
import NewProject from "./views/projects/new-project/NewProject";
import ProjectOverView from "./views/projects/project-overview/ProjectOverView";
import EditProjectedArea from "./views/projects/project-overview/components/tools/projected-area/EditProjectedArea";
import EditDryerSupply from "./views/projects/project-overview/components/tools/dryer-supply/EditDryerSupply";
import EditHooperCapacity from "./views/projects/project-overview/components/tools/hooper-capacity/EditHooperCapacity";
import EditMelt3030 from "./views/projects/project-overview/components/tools/melt3030/EditMelt3030";
import EditShotSize from "./views/projects/project-overview/components/tools/shot-size/EditShotSize";
import EditPressureLoss from "./views/projects/project-overview/components/tools/pressure-loss/EditPressureLoss";
import EditVCheckRepeatability from "./views/projects/project-overview/components/tools/vcheck-repeatability/EditVCheckRepeatability";
import EditViscosityStudy from "./views/projects/project-overview/components/tools/viscosity-study/EditViscosityStudy";
import EditLinearityStudy from "./views/projects/project-overview/components/tools/linearity-study/EditLinearityStudy";
import EditCavityBalance from "./views/projects/project-overview/components/tools/cavity-balance/EditCavityBalance";
import EditSustainingPressure from "./views/projects/project-overview/components/tools/sustaining-pressure/EditSustainingPressure";
import EditGateSealing from "./views/projects/project-overview/components/tools/gate-sealing/EditGateSealing";
import EditCoolingTime from "./views/projects/project-overview/components/tools/cooling-time/EditCoolingTime";
import EditEnduranceTime from "./views/projects/project-overview/components/tools/endurance-time/EditEnduranceTime";
import EditWindowProcess from "./views/projects/project-overview/components/tools/window-process/EditWindowProcess";
import IndividualOverView from "./views/projects/individual/IndividualOverView";
import IndividualIntensificationRatio from "./views/projects/individual/tools/intesification-ratio/IndividualIntensificationRatio";
import Pricing from "./views/Pages/pricing/Pricing";
import Payment from "./views/Pages/pricing/Payment";
import LandingPage from "./views/landing/LandingPage";
import TermsAndConditions from "./views/landing/TermsAndConditions";
import CreateMold from "./views/users/molds/CreateMold";
import CreateMachine from "./views/users/machines/CreateMachine";
import CreateResin from "./views/users/resines/CreateResin";
import CollaboratorsIndex from "./views/collaborators/CollaboratorsIndex";
import LdPlasticSolutions from "./views/collaborators/ld-plastic/LdPlasticSolutions";
import ShelterComponent from "./views/collaborators/shelter/ShelterComponent";
import IntensificationRatioPdf from "./views/projects/individual/tools/intesification-ratio/components/IntensificationRatioPdf";
import IndividualProjectedArea from "./views/projects/individual/tools/projected-area/IndividualProjectedArea";
import ProjectedAreaPdf from "./views/projects/individual/tools/projected-area/components/ProjectedAreaPdf";
import IndividualDryerSupply from "./views/projects/individual/tools/dryer-supply/IndividualDryerSupply";
import IndividualDryerSupplyPdf from "./views/projects/individual/tools/dryer-supply/components/IndividualDryerSupplyPdf";
import IndividualHooperCapacity from "./views/projects/individual/tools/hooper-capacity/IndividualHooperCapacity.jsx";
import IndividualHooperCapacityPdf from "./views/projects/individual/tools/hooper-capacity/components/pdf/IndividualHooperCapacityPdf";
import IndividualMelt3030 from "./views/projects/individual/tools/melt-3030/IndividualMelt3030";
import IndividualMelt3030Pdf from "./views/projects/individual/tools/melt-3030/components/pdf/IndividualMelt3030Pdf";
import IndividualShotSize from "./views/projects/individual/tools/shot-size/IndividualShotSize";
import IndividualShotSizePdf from "./views/projects/individual/tools/shot-size/components/pdf/IndividualShotSizePdf";
import IndividualLossPressure from "./views/projects/individual/tools/loss-pressure/IndividualLossPressure";
import IndividualLossPressurePdf from "./views/projects/individual/tools/loss-pressure/components/pdf/IndividualLossPressurePdf";
import IndividualCheckValveRepeatibility from "./views/projects/individual/tools/check-valve-repeatibility/IndividualCheckValveRepeatibility";
import IndividualCheckValveRepeatibilityPdf from "./views/projects/individual/tools/check-valve-repeatibility/components/pdf/IndividualCheckValveRepeatibilityPdf";
import IndividualViscosityStudy from "./views/projects/individual/tools/viscosity-study/IndividualViscosityStudy";
import IndividualViscosityStudyPdf from "./views/projects/individual/tools/viscosity-study/components/pdf/IndividualViscosityStudyPdf";
import ConvertMachine from "./views/users/machines/convert/ConvertMachine";
import ConvertMold from "./views/users/molds/convert/ConvertMold";
import ConvertResin from "./views/users/resines/convert/ConvertResin";
import InjectionMolding from "./views/Pages/Info/injection-molding/InjectionMolding";
import IntensificationRatioIndex from "./views/projects/individual/tools/intesification-ratio/IntensificationRatioIndex";
import ProjectedAreaIndex from "./views/projects/individual/tools/projected-area/ProjectedAreaIndex";
import DryerSupplyIndex from "./views/projects/individual/tools/dryer-supply/DryerSupplyIndex";
import HooperCapacityIndex from "./views/projects/individual/tools/hooper-capacity/HooperCapacityIndex";
import Melt3030Index from "./views/projects/individual/tools/melt-3030/Melt3030Index";
import ShotSizeIndex from "./views/projects/individual/tools/shot-size/ShotSizeIndex";
import LossPressureIndex from "./views/projects/individual/tools/loss-pressure/LossPressureIndex";
import CheckValveIndex from "./views/projects/individual/tools/check-valve-repeatibility/CheckValveIndex";
import GeaTool from "./views/collaborators/gea/tools/gea-tool/GeaTool";
import GeaPdf from "./views/collaborators/gea/tools/GeaPdf";
import HmsIndex from "./views/collaborators/hms/HmsIndex";
import IndividualViscosityStudyIndex
  from "./views/projects/individual/tools/viscosity-study/IndividualViscosityStudyIndex";
import IndividualLinearityStudy from "./views/projects/individual/tools/linearity-study/IndividualLinearityStudy";
import IndividualLinearityStudyIndex
  from "./views/projects/individual/tools/linearity-study/IndividualLinearityStudyIndex";
import IndividualLinearityStudyPdf
  from "./views/projects/individual/tools/linearity-study/components/pdf/IndividualLinearityStudyPdf";

const dashRoutes = [
  {
    name: "Mi Perfil",
    path: "/profile",
    icon: <HomeIcon color="inherit" />,
    authIcon: <HomeIcon color="inherit" />,
    collapse: true,
    items: [
      {
        name: "Mi Perfil",
        path: "/profile/my-profile",
        component: MyProfile,
        layout: "/admin",
      },
      {
        name: "Planes",
        path: "/profile/plans",
        component: Pricing,
        layout: "/admin",
        hidden: false,
      },
      {
        name: "Tecnicas Plasticas Promoción",
        path: "/profile/promos/tecnicas-plasticas",
        component: Pricing,
        layout: "/admin",
        hidden: true,
      },
      {
        name: "Planes",
        path: "/profile/payment/:id",
        component: Payment,
        layout: "/admin",
        hidden: true,
      },
    ],
  },
  {
    name: "Mis Moldes",
    path: "/tools",
    icon: <HomeIcon color="inherit" />,
    authIcon: <HomeIcon color="inherit" />,
    collapse: true,
    items: [
      {
        name: "Mis Moldes",
        path: "/tools/index",
        component: MyMolds,
        layout: "/admin",
      },
      {
        name: "Crear Molde",
        path: "/tools/create",
        component: CreateMold,
        layout: "/admin",
      },
      {
        name: "Convertir Unidades",
        path: "/tools/convert",
        component: ConvertMold,
        layout: "/admin",
      },
    ],
  },
  {
    name: "Mis Resinas",
    path: "/resin",
    icon: <HomeIcon color="inherit" />,
    authIcon: <HomeIcon color="inherit" />,
    collapse: true,
    items: [
      {
        name: "Mis Resinas",
        path: "/resin/index",
        component: MyResines,
        layout: "/admin",
      },
      {
        name: "Crear Resina",
        path: "/resin/create",
        component: CreateResin,
        layout: "/admin",
      },
      {
        name: "Convertir Unidades",
        path: "/resin/convert",
        component: ConvertResin,
        layout: "/admin",
      },
    ],
  },
  {
    name: "Mis Maquinas",
    path: "/machine",
    icon: <HomeIcon color="inherit" />,
    authIcon: <HomeIcon color="inherit" />,
    collapse: true,
    items: [
      {
        name: "Mis Maquinas",
        path: "/machine/index",
        component: MyMachines,
        layout: "/admin",
      },
      {
        name: "Crear Maquina",
        path: "/machine/create",
        component: CreateMachine,
        layout: "/admin",
      },
      {
        name: "Convertir Unidades",
        path: "/machine/convert",
        component: ConvertMachine,
        layout: "/admin",
      },
    ],
  },
  {
    name: "Inicio",
    component: LandingPage,
    path: "/pages/landing",
    layout: "/banner",
    hidden: true,
  },
  {
    name: "Terminos y Condiciones",
    component: TermsAndConditions,
    path: "/pages/terms-and-conditions",
    layout: "/banner",
    hidden: true,
  },
  {
    name: "Moldeo por Inyeccion",
    component: InjectionMolding,
    path: "/pages/moldeo-por-inyeccion",
    layout: "/banner",
    hidden: true,
  },
  {
    name: "Gea Herramienta",
    path: "/collaborators/gea-tool",
    component: GeaTool,
    layout: "/banner",
    hidden: true,
  },
  {
    name: "Gea Herramienta PDF",
    path: "/collaborators/gea/pdf/:id",
    component: GeaPdf,
    layout: "/banner",
    hidden: true,
  },

  {
    name: "Mis Proyectos",
    category: "projects",
    nav: true,
    items: [
      {
        name: "Proyectos",
        path: "/projects",
        icon: <HomeIcon color="inherit" />,
        authIcon: <HomeIcon color="inherit" />,
        collapse: true,
        items: [
          {
            name: "Mis proyectos",
            path: "/projects/index",
            component: MyProyects,
            layout: "/admin",
          },
          {
            name: "Nuevo Proyecto",
            path: "/projects/new-project",
            component: NewProject,
            layout: "/admin",
          },
          {
            name: "Pruebas Individuales",
            path: "/projects/individual/overview",
            component: IndividualOverView,
            layout: "/admin",
          },
          {
            name: "Ratio de Intensificación",
            path: "/projects/individual/intensification-ratio",
            component: IndividualIntensificationRatio,
            layout: "/admin",
            hidden: true,
          },
          {
            name: "Ratio de Intensificación",
            path: "/projects/individual/intensification-ratio-index",
            component: IntensificationRatioIndex,
            layout: "/admin",
            hidden: true,
          },
          {
            name: "PDF Ratio de Intensificación",
            path: "/projects/pdf/intensification-ratio/:id",
            component: IntensificationRatioPdf,
            layout: "/banner",
            hidden: true,
          },
          {
            name: "PDF Ratio de Area Proyectada y Tonelaje",
            path: "/projects/pdf/projected-area/:id",
            component: ProjectedAreaPdf,
            layout: "/banner",
            hidden: true,
          },
          {
            name: "Area Proyectada y Tonelaje",
            path: "/projects/individual/projected-area",
            component: IndividualProjectedArea,
            layout: "/admin",
            hidden: true,
          },
          {
            name: "Area Proyectada y Tonelaje",
            path: "/projects/individual/projected-area-index",
            component: ProjectedAreaIndex,
            layout: "/admin",
            hidden: true,
          },
          {
            name: "Abastecimiento de Secador",
            path: "/projects/individual/dryer-supply",
            component: IndividualDryerSupply,
            layout: "/admin",
            hidden: true,
          },
          {
            name: "Abastecimiento de Secador",
            path: "/projects/individual/dryer-supply-index",
            component: DryerSupplyIndex,
            layout: "/admin",
            hidden: true,
          },
          {
            name: "PDF Abastecimiento de Secador",
            path: "/projects/pdf/dryer-supply/:id",
            component: IndividualDryerSupplyPdf,
            layout: "/banner",
            hidden: true,
          },
          {
            name: "Capacidad de la Tolva",
            path: "/projects/individual/hooper-capacity",
            component: IndividualHooperCapacity,

            layout: "/admin",
            hidden: true,
          },
          {
            name: "Capacidad de la Tolva",
            path: "/projects/individual/hooper-capacity-index",
            component: HooperCapacityIndex,

            layout: "/admin",
            hidden: true,
          },
          {
            name: "PDF Capacidad de la Tolva",
            path: "/projects/pdf/hopper-capacity/:id",
            component: IndividualHooperCapacityPdf,
            layout: "/banner",
            hidden: true,
          },
          {
            name: "Melt 30/30",
            path: "/projects/individual/melt-3030",
            component: IndividualMelt3030,

            layout: "/admin",
            hidden: true,
          },
          {
            name: "Melt 30/30",
            path: "/projects/individual/melt-3030-index",
            component: Melt3030Index,
            layout: "/admin",
            hidden: true,
          },
          {
            name: "PDF Melt 30/30",
            path: "/projects/pdf/melt-3030/:id",
            component: IndividualMelt3030Pdf,
            layout: "/banner",
            hidden: true,
          },
          {
            name: "Shot Size",
            path: "/projects/individual/shot-size",
            component: IndividualShotSize,

            layout: "/admin",
            hidden: true,
          },
          {
            name: "Shot Size",
            path: "/projects/individual/shot-size-index",
            component: ShotSizeIndex,
            layout: "/admin",
            hidden: true,
          },
          {
            name: "PDF Shot Size",
            path: "/projects/pdf/shot-size/:id",
            component: IndividualShotSizePdf,
            layout: "/banner",
            hidden: true,
          },
          {
            name: "Loss Pressure Analysis",
            path: "/projects/individual/loss-pressure",
            component: IndividualLossPressure,

            layout: "/admin",
            hidden: true,
          },
          {
            name: "Loss Pressure Analysis",
            path: "/projects/individual/loss-pressure-index",
            component: LossPressureIndex,
            layout: "/admin",
            hidden: true,
          },
          {
            name: "PDF Loss Pressure Analysis",
            path: "/projects/pdf/loss-pressure/:id",
            component: IndividualLossPressurePdf,
            layout: "/banner",
            hidden: true,
          },
          {
            name: "Check Valve Repeatibility",
            path: "/projects/individual/check-valve-repeatibility",
            component: IndividualCheckValveRepeatibility,

            layout: "/admin",
            hidden: true,
          },
          {
            name: "Check Valve Repeatibility",
            path: "/projects/individual/check-valve-repeatibility-index",
            component: CheckValveIndex,

            layout: "/admin",
            hidden: true,
          },
          {
            name: "PDF Check Valve Repeatibility",
            path: "/projects/pdf/check-valve-repeatibility/:id",
            component: IndividualCheckValveRepeatibilityPdf,
            layout: "/banner",
            hidden: true,
          },
          {
            name: "Viscosity Study",
            path: "/projects/individual/viscosity-study",
            component: IndividualViscosityStudy,
            layout: "/admin",
            hidden: true,
          },
          {
            name: "Viscosity Study",
            path: "/projects/individual/viscosity-study-index",
            component: IndividualViscosityStudyIndex,
            layout: "/admin",
            hidden: true,
          },
          {
            name: "PDF Viscosity Study",
            path: "/projects/pdf/viscosity-study/:id",
            component: IndividualViscosityStudyPdf,
            layout: "/banner",
            hidden: true,
          },
          {
            name: "Viscosity Study",
            path: "/projects/individual/linearity-study",
            component: IndividualLinearityStudy,
            layout: "/admin",
            hidden: true,
          },
          {
            name: "Viscosity Study",
            path: "/projects/individual/linearity-study-index",
            component: IndividualLinearityStudyIndex,
            layout: "/admin",
            hidden: true,
          },
          {
            name: "PDF Viscosity Study",
            path: "/projects/pdf/linearity-study/:id",
            component: IndividualLinearityStudyPdf,
            layout: "/banner",
            hidden: true,
          },
          {
            name: "Proyecto",
            path: "/project/:id",
            component: ProjectOverView,
            layout: "/admin",
            hidden: true,
          },
          {
            name: "Editar Proyecto",
            path: "/edit-project/projected-area/:id",
            component: EditProjectedArea,
            layout: "/admin",
            hidden: true,
          },
          {
            name: "Editar Proyecto",
            path: "/edit-project/dryer-supply/:id",
            component: EditDryerSupply,
            layout: "/admin",
            hidden: true,
          },
          {
            name: "Editar Proyecto",
            path: "/edit-project/hooper-capacity/:id",
            component: EditHooperCapacity,
            layout: "/admin",
            hidden: true,
          },
          {
            name: "Editar Proyecto",
            path: "/edit-project/melt-3030/:id",
            component: EditMelt3030,
            layout: "/admin",
            hidden: true,
          },
          {
            name: "Editar Proyecto",
            path: "/edit-project/shot-size/:id",
            component: EditShotSize,
            layout: "/admin",
            hidden: true,
          },
          {
            name: "Editar Proyecto",
            path: "/edit-project/pressure-loss/:id",
            component: EditPressureLoss,
            layout: "/admin",
            hidden: true,
          },
          {
            name: "Editar Proyecto",
            path: "/edit-project/vcheck-repeatability/:id",
            component: EditVCheckRepeatability,
            layout: "/admin",
            hidden: true,
          },
          {
            name: "Editar Proyecto",
            path: "/edit-project/viscosity-study/:id",
            component: EditViscosityStudy,
            layout: "/admin",
            hidden: true,
          },
          {
            name: "Editar Proyecto",
            path: "/edit-project/linearity-study/:id",
            component: EditLinearityStudy,
            layout: "/admin",
            hidden: true,
          },
          {
            name: "Editar Proyecto",
            path: "/edit-project/cavity-balance/:id",
            component: EditCavityBalance,
            layout: "/admin",
            hidden: true,
          },
          {
            name: "Editar Proyecto",
            path: "/edit-project/sustaining-pressure/:id",
            component: EditSustainingPressure,
            layout: "/admin",
            hidden: true,
          },
          {
            name: "Editar Proyecto",
            path: "/edit-project/gate-sealing/:id",
            component: EditGateSealing,
            layout: "/admin",
            hidden: true,
          },
          {
            name: "Editar Proyecto",
            path: "/edit-project/cooling-time/:id",
            component: EditCoolingTime,
            layout: "/admin",
            hidden: true,
          },
          {
            name: "Editar Proyecto",
            path: "/edit-project/endurance-time/:id",
            component: EditEnduranceTime,
            layout: "/admin",
            hidden: true,
          },
          {
            name: "Editar Proyecto",
            path: "/edit-project/window-process/:id",
            component: EditWindowProcess,
            layout: "/admin",
            hidden: true,
          },
        ],
      },
    ],
  },
  {
    name: "COLABORADORES",
    category: "collaborators",
    nav: false,
    items: [
      {
        name: "Colaboradores",
        path: "/collaborators",
        icon: <HomeIcon color="inherit" />,
        authIcon: <HomeIcon color="inherit" />,
        collapse: true,
        items: [
          {
            name: "Index",
            path: "/collaborators/index",
            component: CollaboratorsIndex,
            layout: "/admin",
          },
          {
            name: "Tecnicas Plasticas",
            path: "/collaborators/tecnicas-plasticas",
            component: TecnicasPlasticas,
            layout: "/admin",
          },
          {
            name: "Gea",
            path: "/collaborators/gea",
            component: Gea,
            layout: "/admin",
          },
          {
            name: "G-Intec",
            path: "/collaborators/gintec",
            component: Gintec,
            layout: "/admin",
          },
          {
            name: "Rediassa",
            path: "/collaborators/rediassa",
            component: Rediassa,
            layout: "/admin",
          },
          {
            name: "AsaClean",
            path: "/collaborators/asaclean",
            component: AsaClean,
            layout: "/admin",
          },

          {
            name: "Somos Shelter",
            path: "/collaborators/shelter",
            component: ShelterComponent,
            layout: "/admin",
          },
          {
            name: "LD Plastic Solution",
            path: "/collaborators/ld-plastic-solutions",
            component: LdPlasticSolutions,
            layout: "/admin",
          },
          {
            name: "HMS",
            path: "/collaborators/hms",
            component: HmsIndex,
            layout: "/admin",
          },
        ],
      },
    ],
  },
  {
    name: "Tecnicas Plasticas",
    component: TecnicasPlasticas,
    path: "/collaborators/tecnicas-plasticas",
    layout: "/banner",
    hidden: true,
  },
  {
    name: "Gea",
    component: Gea,
    path: "/collaborators/gea",
    layout: "/banner",
    hidden: true,
  },
  {
    name: "G-Intec",
    component: Gintec,
    path: "/collaborators/gintec",
    layout: "/banner",
    hidden: true,
  },
  {
    name: "HMS",
    component: HmsIndex,
    path: "/collaborators/hms",
    layout: "/banner",
    hidden: true,
  },
  {
    name: "Rediassa",
    component: Rediassa,
    path: "/collaborators/rediassa",
    layout: "/banner",
    hidden: true,
  },
  {
    name: "AsaClean",
    component: AsaClean,
    path: "/collaborators/asaclean",
    layout: "/banner",
    hidden: true,
  },
  {
    name: "Somos Shelter",
    component: ShelterComponent,
    path: "/collaborators/shelter",
    layout: "/banner",
    hidden: true,
  },
  {
    name: "LD Plastic Solution",
    component: LdPlasticSolutions,
    path: "/collaborators/ld-plastic-solutions",
    layout: "/banner",
    hidden: true,
  },

  /*
  {
    name: "HERRAMIENTAS",
    category: "tools",
    items: [
      {
        name: "Herramientas",
        path: "/tools",
        icon: <HomeIcon color="inherit" />,
        authIcon: <HomeIcon color="inherit" />,
        collapse: true,
        items: [
          {
            name: "Razon de Intensificación",
            path: "/tools/intensification-ratio",
            component: IntensificationRatio,
            layout: "/admin",
          },
          {
            name: "Area Proyectada y Tonelaje",
            path: "/tools/projected-area-and-tonnage",
            component: ProjectedAreaAndTonnage,
            layout: "/admin",
          },
          {
            name: "Abastecimiento de Secador",
            path: "/tools/dryer-supply",
            component: DryerSupply,
            layout: "/admin",
          },
          {
            name: "Capacidad de Tolva",
            path: "/tools/hopper-capacity",
            component: HooperCapacity,
            layout: "/admin",
          },
          {
            name: "Melt 30/30",
            path: "/tools/melt-3030",
            component: Melt3030,
            layout: "/admin",
          },
          {
            name: "Tamaño de Disparo",
            path: "/tools/shoot-size",
            component: ShootSize,
            layout: "/admin",
          },
          {
            name: "Estudio de pérdida de presión",
            path: "/tools/pressure-loos-test",
            component: PressureLossTest,
            layout: "/admin",
          },
          {
            name: "Repetibilidad de V Check",
            path: "/tools/v-check-reliavility",
            component: VCheckReliability,
            layout: "/admin",
          },
          {
            name: "Estudio de Viscosidad",
            path: "/tools/viscosity-study",
            component: ViscosityStudy,
            layout: "/admin",
          },
          {
            name: "Balance de Cavidades",
            path: "/tools/cavity-imbalance",
            component: CavityImbalance,
            layout: "/admin",
          },
          {
            name: "Sellado de Compuerta",
            path: "/tools/gate-seal",
            component: GateSeal,
            layout: "/admin",
          },
        ],
      },
      {
        name: "Premium",
        path: "/premium-tools",
        icon: <HomeIcon color="inherit" />,
        authIcon: <HomeIcon color="inherit" />,
        collapse: true,
        items: [
          {
            name: "Mapeo de Ciclo",
            path: "/premium-tools/cycle-maping",
            component: CycleMaping,
            layout: "/admin",
          },
          {
            name: "Coste de Pieza Moldeada",
            path: "/premium-tools/costing-of-molded-part",
            component: CostingOfMoldedPart,
            layout: "/admin",
          },
          {
            name: "Remolido (Uso y Generaciones)",
            path: "/premium-tools/regrind",
            component: Regrind,
            layout: "/admin",
          },
          {
            name: "Igualación de Proceso",
            path: "/premium-tools/match-process",
            component: MatchProcess,
            layout: "/admin",
          },
          {
            name: "Glosario",
            path: "/premium-tools/glossary",
            component: Glossary,
            layout: "/admin",
          },
        ],
      },
    ],
  },


  */
];

export default dashRoutes;
