import { useIndividualHooks } from "../hooks/useIndividualHooks";
import IndividualFooter from "../../../components/IndividualFooter";
import { useVerifyForm } from "../../../hooks/useVerifyForm";
import { keysForForm } from "../utilities/variables";
import React, { useEffect, useRef, useState } from "react";
import { isObjectEmpty } from "../../../../../../utilities/functions";
import ShowResults from "../../../components/ShowResults";
import { dataResults } from "../utilities/dataToShow";
import Form from "./Form";
import { areTablePropertiesComplete } from "../utilities/areTablePropertiesComplete";

import {
  Box,
  Button,
  Flex,
  FormLabel,
  Grid,
  Input,
  Text,
} from "@chakra-ui/react";
import ViscosityStudyHeader from "../../../../project-overview/components/tools/viscosity-study/components/ViscosityStudyHeader";
import {
  borderColor,
  textColor,
} from "../../../../project-overview/components/utilities/variables";
import CardHeader from "../../../../../../components/Card/CardHeader";
import { cardHeaderStyles } from "../../../../../../theme/styles";
import Select from "react-select";
import { selectOptions } from "../../../../project-overview/components/tools/viscosity-study/utilities/variables";
import LineChart from "../../../../../../components/Charts/LineChart";

const IndividualForm = ({ setIsDisabled }) => {
  const {
    uom,
    toolName,
    resinName,
    productName,
    machineName,
    data,
    onChangeHandler,
    onSubmitHandler,
    isLoading,
    result,
    showDataTable,
    onChangeTable,
    isTableFormComplete,
    fillTable,
    showChart,
    setShowchart,
    lineChartOptionsMatrix,
    lineChartDataMatrix,
    onChangeSequenceHandler,
    onChangeMaxSelectedSequence,
    maxSequenceOptions,
    setShowDataTable,
    chartRef,
    onFinishHandler,
  } = useIndividualHooks(setIsDisabled);

  const [validLines, setValidLines] = useState(["1"]);

  const isDisabled = !useVerifyForm(data, keysForForm) && !isTableFormComplete;
  const isFormDisabled = !isObjectEmpty(result);

  const isTableFormFilled = areTablePropertiesComplete(data?.table);

  const thFontSize = "10px";

  const optionsWithLastDisabled = selectOptions.map((option, index) =>
    index === selectOptions.length - 1
      ? { ...option, isDisabled: true } // Desactivar la última opción
      : option
  );

  useEffect(() => {
    isValidLine();
  }, [data]);

  const isValidLine = () => {
    setValidLines(["1"]);

    const updatedValidLines = [];
    for (const item of data?.table || []) {
      // Verifica si el elemento cumple con todas las condiciones
      if (
        item.fillTime &&
        item.maxPress &&
        item.shotWeight &&
        item.vpTransfer
      ) {
        updatedValidLines.push((parseInt(item.sequence) + 1).toString());
      } else {
        break; // Detén la iteración si una línea no cumple
      }
    }

    setValidLines(["1", ...updatedValidLines]); // Actualiza validLines solo con las secuencias válidas consecutivas
  };

  if (uom && resinName && toolName && machineName && productName) {
    return (
      <>
        <Form
          setShowchart={setShowchart}
          showDataTable={showDataTable}
          isLoading={isLoading}
          onSubmit={onSubmitHandler}
          isDisabled={isDisabled}
          onChange={onChangeHandler}
          data={data}
          disabled={isFormDisabled}
          setShowDataTable={setShowDataTable}
        />

        {showDataTable && (
          <>
            <ShowResults repeat={4} data={dataResults(data)} />

            <Grid
              borderColor={borderColor}
              color={textColor}
              p={5}
              borderWidth={1}
              borderRadius={15}
              mt={5}
              mx={5}
            >
              <Flex direction={"column"} gap="24px" w={"100%"}>
                <CardHeader {...cardHeaderStyles}>
                  <Grid justifyItems={"center"}>
                    <Text color={textColor} fontSize="lg" fontWeight="bold">
                      Realiza los experimentos necesarios para completar el
                      estudio.
                    </Text>
                    <Text color={textColor} fontSize="sm">
                      Configura la Máquina de Inyección con la Velocidad de
                      Inyección, de acuerdo a la secuencia deseada y llena los
                      campos requeridos. Con mayor numero de decimales se
                      obtendrán resultados mas precisos.
                    </Text>
                  </Grid>
                </CardHeader>
                <ViscosityStudyHeader uom={data?.header?.uom} />
                {data?.table?.map((item, index) => (
                  <Grid
                    alignItems={"center"}
                    justifyContent={"center"}
                    color={
                      showChart &&
                      parseInt(data?.selectedSequence) <=
                        parseInt(item.sequence) &&
                      parseInt(data?.maxSelectedSequence) >=
                        parseInt(item.sequence)
                        ? "white"
                        : textColor
                    }
                    fontSize={thFontSize}
                    templateColumns="repeat(12, 1fr)"
                    justifyItems={"center"}
                    gap={
                      showChart &&
                      parseInt(data?.selectedSequence) <=
                        parseInt(item.sequence) &&
                      parseInt(data?.maxSelectedSequence) >=
                        parseInt(item.sequence)
                        ? 0
                        : 1
                    }
                    bgColor={
                      showChart &&
                      parseInt(data?.selectedSequence) <=
                        parseInt(item.sequence) &&
                      parseInt(data?.maxSelectedSequence) >=
                        parseInt(item.sequence)
                        ? "green"
                        : ""
                    }
                  >
                    <Text fontSize={thFontSize}>{item.sequence}</Text>
                    <Text>{parseFloat(item.speed).toFixed(3)}</Text>
                    <Input
                      borderColor={borderColor}
                      disabled={
                        showChart || !validLines.includes(item.sequence)
                      }
                      onChange={(e) => onChangeTable(e)}
                      borderRadius={2}
                      type={"text"}
                      name={`table.${index}.fillTime`}
                      value={item.fillTime}
                      placeholder={"ej: 0.5"}
                      size={"xs"}
                    />
                    <Input
                      borderColor={borderColor}
                      onChange={(e) => onChangeTable(e)}
                      borderRadius={2}
                      type={"number"}
                      disabled={
                        showChart || !validLines.includes(item.sequence)
                      }
                      name={`table.${index}.maxPress`}
                      value={item.maxPress}
                      placeholder={"ej: 1720"}
                      size={"xs"}
                    />
                    <Input
                      borderColor={borderColor}
                      onChange={(e) => onChangeTable(e)}
                      borderRadius={2}
                      type={"number"}
                      disabled={
                        showChart || !validLines.includes(item.sequence)
                      }
                      name={`table.${index}.shotWeight`}
                      value={item.shotWeight}
                      placeholder={"ej: 0.675"}
                      size={"xs"}
                    />
                    <Input
                      borderColor={borderColor}
                      onChange={(e) => onChangeTable(e)}
                      borderRadius={2}
                      type={"number"}
                      disabled={
                        showChart || !validLines.includes(item.sequence)
                      }
                      name={`table.${index}.vpTransfer`}
                      value={item.vpTransfer}
                      placeholder={"ej: 0.29"}
                      size={"xs"}
                    />
                    <Text
                      color={
                        parseInt(data.maxPressReq) <
                          parseInt(item.maxPressPpsi) && "red"
                      }
                    >
                      {parseInt(item.maxPressPpsi)}
                    </Text>
                    <Text>{parseFloat(item.flowRate).toFixed(4)}</Text>
                    <Text>{parseFloat(item.realSpeed).toFixed(4)}</Text>
                    <Text
                      color={
                        parseFloat(item.efficence).toFixed(4) <
                          parseFloat(data.processEfficence) && "red"
                      }
                    >
                      {parseFloat(item.efficence).toFixed(4)}
                    </Text>
                    <Text>{parseFloat(item.shearRate).toFixed(4)}</Text>
                    <Text>{parseInt(item.relVisc)}</Text>
                  </Grid>
                ))}
              </Flex>
            </Grid>
            <Flex direction={"row"}>
              <Button
                mr={showChart && "6"}
                onClick={() => fillTable()}
                mt={5}
                disabled={!isTableFormFilled || showChart}
                colorScheme={"facebook"}
              >
                Continuar
              </Button>
              {showChart && (
                <Button
                  onClick={() => setShowchart(false)}
                  mt={5}
                  colorScheme={"facebook"}
                >
                  Editar Tabla
                </Button>
              )}
            </Flex>
          </>
        )}

        {showChart && (
          <Grid
            width={"100%"}
            p={5}
            borderWidth={1}
            borderColor={borderColor}
            borderRadius={15}
            mt={5}
            my={5}
            mx={5}
          >
            <Flex justifyContent={"center"}>
              <Flex
                w={"25%"}
                color={"black"}
                direction={"column"}
                justifyContent={"center"}
              >
                <FormLabel color={textColor}>Seleccion de Secuencia</FormLabel>
                <Select
                  value={selectOptions[parseInt(data.selectedSequence) - 1]}
                  defaultValue={
                    selectOptions[parseInt(data.selectedSequence) - 1]
                  }
                  onChange={(e) => onChangeSequenceHandler(e)}
                  options={optionsWithLastDisabled}
                  placeholder={"Selecciona una Secuencia"}
                />

                <FormLabel color={textColor}>Secuencia Máxima</FormLabel>
                <Select
                  value={selectOptions[parseInt(data.maxSelectedSequence) - 1]}
                  defaultValue={
                    selectOptions[parseInt(data.maxSelectedSequence) - 1]
                  }
                  onChange={(e) => onChangeMaxSelectedSequence(e)}
                  options={maxSequenceOptions}
                  placeholder={"Selecciona una Secuencia"}
                />
              </Flex>
            </Flex>
            <Flex
              mt={10}
              color={textColor}
              direction={"column"}
              w={"80%"}
              minHeight={"300px"}
              alignSelf={"center"}
            >
              <Flex
                color={textColor}
                direction={"column"}
                w={"100%"}
                borderColor={"green"}
                position={"relative"}
              >
                <div ref={chartRef}>
                  <Box w="100%" minH={{ sm: "450px" }}>
                    <LineChart
                      chartOptions={lineChartOptionsMatrix}
                      chartData={lineChartDataMatrix}
                    />
                  </Box>
                </div>
              </Flex>
            </Flex>
          </Grid>
        )}

        <IndividualFooter
          result={result}
          url={"viscosity-study"}
          isLoading={isLoading}
          onSubmitHandler={onFinishHandler}
          isDisabled={isDisabled || !showChart}
        />
      </>
    );
  } else {
    return <></>;
  }
};

export default IndividualForm;
