import {delay} from "../hooks/useDelay";
import html2canvas from "html2canvas";

export const useIndividualGlobalHooks = () => {
  const  createImages = async (chartRef) => {
    await delay(500);
    const canvas = await html2canvas(chartRef.current);

    // Convertir el canvas a blob
    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (blob) {
          resolve(blob);
        } else {
          reject(new Error("Falló la conversión de canvas a blob"));
        }
      }, "image/png");
    });
  };

  return {
    createImages
  }
}
