export const tableInitialState = Array.from({ length: 10 }, (_, index) => ({
  sequence: (index + 1).toString(),
  speed: "",
  vpTransfer: "",
  fillTime: "",
  maxPress: "",
  cavityWeight: "",
  realSpeed: "",
  realViscosity: "",
  maxPress2: "",
  reologhy: "",
  linearity: "",
  maxShearRate: "",
}));
