import { useHistory } from "react-router-dom";
import { Flex, Grid, GridItem, Heading, Text } from "@chakra-ui/react";
import CustomGrid from "../../projects/individual/components/CustomGrid";
import Brand from "./components/Brand";

const HmsIndex = () => {
  const history = useHistory();
  const isBannerPath = history.location.pathname.includes("banner");
  const bgColor = "#c3cbd9";
  const brandColor = "#92051a";

  return (
    <Flex
      direction="column"
      pt={{ sm: "150px", lg: "150" }}
      width={isBannerPath ? "80%" : "auto"}
      mx={isBannerPath ? "auto" : "0"}
      mb={10}
    >
      <Grid
        borderRadius={15}
        templateColumns="repeat(4, 1fr)"
        justifyItems={"center"}
      >
        <GridItem
          colSpan={4}
          backgroundColor={bgColor}
          width={"100%"}
          borderTopLeftRadius={15}
          borderTopRightRadius={15}
          py={5}
        >
          <Grid templateColumns="repeat(2, 1fr)">
         <Brand color1={brandColor} color2={"white"} />
          </Grid>
        </GridItem>
        <GridItem
          colSpan={4}
          backgroundColor={brandColor}
          width={"100%"}
          py={5}
        >
          <Grid
            templateColumns="1fr"
            alignItems="center"
            justifyContent="center"
            height="100%"
            px={5}
          >
            <Text
              fontWeight={"bold"}
              align={"center"}
              color={bgColor}
              fontSize={"xl"}
              py={5}
            >
              Empresa dedicada al maquinado, reparación y fabricación de piezas
              metálicas ubicada en el centro de Hermosillo, Sonora.
            </Text>
            <Text
              py={5}
              fontWeight={"bold"}
              align={"center"}
              color={bgColor}
              fontSize={"xl"}
            >
              Con amplia experiencia en la industria automotriz, minera y
              aeroespacial de Sonora y Arizona.
            </Text>
            <Text
              py={5}
              fontWeight={"bold"}
              align={"center"}
              color={bgColor}
              fontSize={"xl"}
            >
              Cuenta con un catálogo de suministros para fixtures de medición y
              ensamble, así como refacciones de maquinaria de fabricación como
              CNC, EDM y Soldadura.
            </Text>
            <Text
              py={5}
              fontWeight={"bold"}
              align={"center"}
              color={bgColor}
              fontSize={"xl"}
            >
              Una empresa con crecimiento sostenido y satisfacción garantizada.
            </Text>
          </Grid>
        </GridItem>
        <GridItem colSpan={4} backgroundColor={bgColor} width={"100%"} py={5}>
          <Grid
            templateColumns="repeat(2, 1fr)"
            gap={6}
            alignItems="center"
            justifyContent="center"
            height="100%"
            px={5}
          >
            <Text
              fontWeight={"bold"}
              align={"center"}
              color={"black"}
              fontSize={"xl"}
              pt={5}
            >
              SERVICIOS DE MAQUINADO
            </Text>
            <GridItem />
            <GridItem
              colSpan={4}
              borderWidth={3}
              borderColor={brandColor}
              borderRadius={15}
            >
              <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                <Grid p={5} justifyContent={"center"}>
                  <Text fontWeight={"bold"} color={brandColor}>
                    FRESADO CONVENCIONAL Y CNC
                  </Text>
                  <Text fontWeight={"bold"} color={brandColor}>
                    TORNO CONVENCIONAL Y CNC
                  </Text>
                  <Text fontWeight={"bold"} color={brandColor}>
                    CORTE DE HILO - WEDM
                  </Text>
                  <Text fontWeight={"bold"} color={brandColor}>
                    CORTE POR PENETRACIÓN - SINKER
                  </Text>
                  <Text fontWeight={"bold"} color={brandColor}>
                    TROQUELADO
                  </Text>
                  <Text fontWeight={"bold"} color={brandColor}>
                    RECTIFICADO
                  </Text>
                </Grid>
                <Grid p={5} justifyContent={"center"}>
                  <Text fontWeight={"bold"} color={brandColor}>
                    imagen
                  </Text>
                </Grid>
              </Grid>
            </GridItem>
            <Text
              fontWeight={"bold"}
              align={"center"}
              color={"black"}
              fontSize={"xl"}
              pt={5}
            >
              MANTENIMIENTO DE MÁQUINA Y HERRAMIENTA
            </Text>
            <GridItem />
            <GridItem
              colSpan={4}
              borderColor={brandColor}
              borderWidth={3}
              borderRadius={15}
            >
              <Grid
                templateColumns="repeat(2, 1fr)"
                gap={6}
                justifyContent={"center"}
              >
                <Grid p={5} justifyContent={"center"}>
                  <Text fontWeight={"bold"} color={brandColor}>
                    imagen
                  </Text>
                </Grid>
                <Grid p={5} justifyContent={"center"}>
                  <Text fontWeight={"bold"} color={brandColor}>
                    ASESORÍA VENTA E INSTALACIÓN DE ACCESORIOS Y REFACCIONES
                  </Text>
                  <Text fontWeight={"bold"} color={brandColor}>
                    TÉCNICOS CAPACITADOS
                  </Text>
                </Grid>
              </Grid>
            </GridItem>
            <Text
              fontWeight={"bold"}
              align={"center"}
              color={"black"}
              fontSize={"xl"}
              pt={5}
            >
              ACCESORIOS PARA TABLEROS DE ARNÉS
            </Text>
            <GridItem />
            <GridItem
              colSpan={4}
              borderWidth={3}
              borderColor={brandColor}
              borderRadius={15}
            >
              <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                <Grid p={5} justifyContent={"center"}>
                  <Text fontWeight={"bold"} color={brandColor}>
                    HORQUILLAS, BASES Y STUDS
                  </Text>
                  <Text fontWeight={"bold"} color={brandColor}>
                    CANDADO VERTICAL PARA HOLDER
                  </Text>
                  <Text fontWeight={"bold"} color={brandColor}>
                    UNIÓN PARA TABLERO
                  </Text>
                  <Text fontWeight={"bold"} color={brandColor}>
                    CLAVO TABLERO DE ARNÉS ELÉCTRICO
                  </Text>
                </Grid>
                <Grid p={5} justifyContent={"center"}>
                  <Text fontWeight={"bold"} color={brandColor}>
                    imagen
                  </Text>
                </Grid>
              </Grid>
            </GridItem>
            <Text
              fontWeight={"bold"}
              align={"center"}
              color={"black"}
              fontSize={"xl"}
              pt={5}
            >
              REFACCIONES Y ACCESORIOS
            </Text>
            <GridItem />
            <GridItem
              colSpan={4}
              borderColor={brandColor}
              borderWidth={3}
              borderRadius={15}
            >
              <Grid
                templateColumns="repeat(2, 1fr)"
                gap={6}
                justifyContent={"center"}
              >
                <Grid p={5} justifyContent={"center"}>
                  <Text fontWeight={"bold"} color={brandColor}>
                    imagen
                  </Text>
                </Grid>
                <Grid p={5} justifyContent={"center"}>
                  <Text fontWeight={"bold"} color={brandColor}>
                    HORQUILLAS, BASES Y STUDS
                  </Text>
                  <Text fontWeight={"bold"} color={brandColor}>
                    CANDADO VERTICAL PARA HOLDER
                  </Text>
                  <Text fontWeight={"bold"} color={brandColor}>
                    UNIÓN PARA TABLERO
                  </Text>
                  <Text fontWeight={"bold"} color={brandColor}>
                    CLAVO TABLERO DE ARNÉS ELÉCTRICO
                  </Text>
                  <Text fontWeight={"bold"} color={brandColor}>
                    CLAVO TABLERO DE ARNÉS ELÉCTRICO
                  </Text>
                  <Text fontWeight={"bold"} color={brandColor}>
                    Y MÁS
                  </Text>
                </Grid>
              </Grid>
            </GridItem>
          </Grid>
        </GridItem>
        <GridItem
          colSpan={4}
          backgroundColor={brandColor}
          width={"100%"}
          py={5}
        >
          <Grid templateColumns="repeat(2, 1fr)">
            <Brand color1={bgColor} color2={bgColor} />
            <GridItem colSpan={2}>
              <Grid p={5} justifyContent={"center"} alignItems={"center"}>
                <Text fontWeight={"bold"}  fontSize={"xl"} color={bgColor} py={5} align={"center"}>
                  Conoce nuestro catalogo de productos en:
                </Text>
                <Text fontWeight={"bold"} fontSize={"xl"} color={bgColor} py={5} align={"center"}>
                  hmsmaquinados.com/shop
                </Text>
                <Text fontWeight={"bold"}  fontSize={"xl"} color={bgColor} py={5} align={"center"}>
                  Encontrarás una gran variedad de productos, refacciones, equipos, suministros y herramientas.
                </Text>
                <Text fontWeight={"bold"}  fontSize={"xl"} color={bgColor} py={5} align={"center"}>
                  info@hmsmaquinados.com
                </Text>
                <Text fontWeight={"bold"}  fontSize={"xl"} color={bgColor} py={5} align={"center"}>
                  www.hmsmaquinados.com
                </Text>
                <Text fontWeight={"bold"}  fontSize={"xl"} color={bgColor} py={5} align={"center"}>
                  +521 662 425 7644
                </Text>
              </Grid>
            </GridItem>

          </Grid>
        </GridItem>


        <GridItem
          colSpan={4}
          backgroundColor={bgColor}
          width={"100%"}
          py={5}
        >
          <Grid templateColumns="repeat(3, 1fr)">
            <Brand color1={brandColor} color2={"white"} />
            <GridItem colSpan={2}>
              <Grid p={5} justifyContent={"center"} alignItems={"center"}>
                <Text fontWeight={"bold"}  fontSize={"xl"} color={brandColor} py={5} align={"center"}>
                  HMS Maquinados y Soluciones es una empresa dedicada al maquinado, reparación y fabricación de piezas metálicas ubicada en el centro de Hermosillo, Sonora desde hace mas de 13 años.

                </Text>
                <Text fontWeight={"bold"} fontSize={"xl"} color={brandColor} py={5} align={"center"}>
                  Con amplia experiencia en la industria automotriz, minera y aeroespacial de Sonora y Arizona.
                </Text>
                <Text fontWeight={"bold"} fontSize={"xl"} color={brandColor} py={5} align={"center"}>
                  ATENCIÓN
                </Text>
                <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                  <Text fontWeight={"bold"} fontSize={"xl"} color={brandColor} py={5} align={"center"}>
                    ARIZONA<br />
                    2633 E Indian School Rd 230,<br />
                    Phoenix, Az. 85016<br />
                    USA
                  </Text>
                  <Text fontWeight={"bold"} fontSize={"xl"} color={brandColor} py={5} align={"center"}>
                    SONORA<br />
                    García Morales 144,<br />
                    Hermosillo, Son. 83000<br />
                    México
                  </Text>
                </Grid>
              </Grid>
            </GridItem>
            <Text fontWeight={"bold"} fontSize={"xl"} color={brandColor} py={5} align={"center"}>
              imagen
            </Text>

          </Grid>
        </GridItem>
        <GridItem
          colSpan={4}
          backgroundColor={brandColor}
          width={"100%"}
          borderBottomLeftRadius={15}
          borderBottomRightRadius={15}
          py={5}
        >
          <Grid templateColumns="repeat(2, 1fr)">
          <Brand color1={bgColor} color2={"white"} />
          </Grid>
        </GridItem>
      </Grid>
    </Flex>
  );
};

export default HmsIndex;
