import { initialStateHeader } from "../../../utilities/variabled";
import {tableInitialState} from "./tableInitialState";

export const initialState = {
  header: initialStateHeader,
  shotWeight100: "",
  numberOfCavities: "",
  screwDiameter: "",
  pistonDiameter: "",
  shotSizePosition: "",
  decompressionSize: "",
  cutoffPosition: "",
  cushionPositionTheoric: "",
  maxInjectionSpeed: "",
  immMaxHPress: "",
  specificWeight: "",
  shotWeightFirstStage: "",
  gateForm: "",
  gateDiameter: "",
  widthGate: "",
  heightGate: "",
  depthGate: "",
  correctCoef: "",
  resinMaxShearRate: "",
  rangeLinearity: "",
  maxMachineUse: "",
  overallShotSize: "",
  screwArea: "",
  pistonArea: "",
  intRatio: "",
  shotVolume: "",
  maxPlasticPressure: "",
  maxPpsiUsed: "",
  speedIntervalUnit: "",
  lineHighLimit: "2",
  lineLowLimit: "1",
  table: tableInitialState,
};



export const keysForForm = [
  "shotWeight100",
  "numberOfCavities",
  "screwDiameter",
  "pistonDiameter",
  "shotSizePosition",
  "decompressionSize",
  "cutoffPosition",
  "cushionPositionTheoric",
  "maxInjectionSpeed",
  "immMaxHPress",
  "specificWeight",
  "shotWeightFirstStage",
  "gateForm",
  "correctCoef",
  "resinMaxShearRate",
  "rangeLinearity",
  "maxMachineUse",

];
export const keysForResult = [
  "screwArea",
  "pistonArea",
  "overallShotSize",
  "intRatio",
  "maxPlasticPress",
  "speedIntervalUnit",
  "maxPressReq",
  "selectedSequence",
];

export const tableHeader = [
  {
    text: "Secuencia",
    uom: "",
  },
  {
    text: "Velocidad",
    uom: "",
  },
  {
    text: "Transferencia VP",
    uom: "",
  },
  {
    text: "Tiempo de Llenado",
    uom: "seg",
  },
  {
    text: "Max Press",
    uom: "",
  },
  {
    text: "Peso de Cavidades",
    uom: "",
  },
  {
    text: "Velocidad Real",
    uom: "",
  },
  {
    text: "Visc. Rel.",
    uom: "",
  },
  {
    text: "MAX Presión",
    uom: "Condicional",
  },
  {
    text: "Reología",
    uom: "Condicional",
  },
  {
    text: "Linealidad",
    uom: "Condicional",
  },
  {
    text: "MAX Shear Rate",
    uom: "Condicional",
  },
]




