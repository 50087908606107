import {
  hpsiToHbar,
  inMm,
  inSecMmsec,
  ozGr,
  temp,
} from "../../../hooks/useUomTransform";

export const inputData = (data) => {
  const { uom } = data?.header;
  return [
    {
      label: "Peso de Disparo al 100%",
      value: data?.shotWeight100,
      id: "shotWeight100",
      addOn: ozGr(uom),
      error:
        parseFloat(data?.shotWeight100) <= 0  &&
        "Peso de Disparo al 100% debe de ser mayor a 0",
      type: "number",
    },
    {
      label: "Número de Cavidades",
      value: data?.numberOfCavities,
      id: "numberOfCavities",
      addOn: "pcs",
      type: "number",
      error: parseFloat(data?.numberOfCavities) <= 0  &&
        "Peso de Disparo al 100% debe de ser mayor a 0 (cero)",
    },
    {
      label: "Diámetro de Tornillo",
      value: data?.screwDiameter,
      id: "screwDiameter",
      addOn: inMm(uom),
      type: "number",
      error: parseFloat(data?.screwDiameter) >= parseFloat(data?.pistonDiameter) &&
        "El Diámetro del tornillo debe de ser menor al diámetro del piston"

    },
    {
      label: "Diámetro de Pistón",
      value: data?.pistonDiameter,
      id: "pistonDiameter",
      addOn: inMm(uom),
      type: "number",
      error: parseFloat(data?.screwDiameter) >= parseFloat(data?.pistonDiameter) &&
        "El Diámetro del pistón debe de ser mayor al diámetro del tornillo"
    },
    {
      label: "Posición de Disparo",
      value: data?.shotSizePosition,
      id: "shotSizePosition",
      addOn: inMm(uom),
      type: "number",
      error: parseFloat(data?.shotSizePosition) <= parseFloat(data?.cutoffPosition) &&
        "La posición de disparo debe de ser mayor a Posición de Transferencia V/P"
    },
    {
      label: "Tamaño de Descompresión",
      value: data?.decompressionSize,
      id: "decompressionSize",
      addOn: inMm(uom),
      type: "number",
      error: parseFloat(data?.decompressionSize) <= 0 &&
        "El Tamaño de Descompresión debe de ser mayor a 0 (Cero)"
    },
    {
      label: "Posición de Transferencia V/P",
      value: data?.cutoffPosition,
      id: "cutoffPosition",
      addOn: inMm(uom),
      type: "number",
      error: parseFloat(data?.shotSizePosition) < parseFloat(data?.cutoffPosition) &&
        "La Posición de Transferencia V/P debe de ser menor al tamaño de disparo."
    },
    {
      label: "Posición Teórica de Colchón",
      value: data?.cushionPositionTheoric,
      id: "cushionPositionTheoric",
      addOn: inMm(uom),
      type: "number",
      error: parseFloat(data?.cushionPositionTheoric) >= parseFloat(data?.cutoffPosition) &&
        "La Posición Teórica de Colchón debe de ser menor al tamaño de disparo."
    },
    {
      label: "Máxima Velocidad de Inyección",
      value: data?.maxInjectionSpeed,
      id: "maxInjectionSpeed",
      addOn: inSecMmsec(uom),
      type: "number",
      error: parseFloat(data?.maxInjectionSpeed) <= 0 &&
        "La Máxima Velocidad de Inyección debe de ser mayor a 0 (cero)"
    },
    {
      label: "Máxima Presión de Inyección",
      value: data?.immMaxHPress,
      id: "immMaxHPress",
      addOn: hpsiToHbar(uom),
      type: "number",
      error: parseFloat(data?.immMaxHPress) <= 0 &&
        "La Máxima Presión de Inyección debe de ser mayor a 0 (Cero)"
    },
    {
      label: "Peso Específico",
      value: data?.specificWeight,
      id: "specificWeight",
      addOn: ":1",
      type: "number",
      error: parseFloat(data?.specificWeight) <= 0 &&
        "El Peso Específico debe de ser mayor a 0 (Cero)"
    },

    {
      label: "Peso de Cavidades 1a Etapa",
      value: data?.shotWeightFirstStage,
      id: "shotWeightFirstStage",
      addOn: ozGr(uom),
      type: "number",
      error: parseFloat(data?.shotWeightFirstStage) >= parseFloat(data?.shotWeight100) &&
        "El Peso de Cavidades 1a Etapa debe de ser menor al Peso de Disparo al 100%"
    },
    {
      label: "Forma de Compuerta",
      value: data?.gateForm,
      id: "gateForm",
      addOn: "",
      type: "select",
      error: "",
      placeholder: "Selecciona una forma",
      options: [
        {text: "Circulo", value: "circle"},
        {text: "Rectangulo", value: "rectangle"},
      ]
    },
    {
      label: "Diámetro de Compuerta",
      value: data?.gateDiameter,
      id: "gateDiameter",
      addOn: inMm(uom),
      type: "number",
      disabled: data?.gateForm !== "circle",
      error: parseFloat(data?.gateDiameter) <= 0 &&
        "El Diámetro de la compuerta debe de ser mayor a 0 (cero)"
    },
    {
      label: "Grosor de Compuerta",
      value: data?.widthGate,
      id: "widthGate",
      addOn: inMm(uom),
      type: "number",
      disabled: data?.gateForm !== "rectangle",
      error: parseFloat(data?.widthGate) <= 0 &&
        "El Grosor de la compuerta debe de ser mayor a 0 (cero)"
    },
    {
      label: "Altura de Compuerta",
      value: data?.heightGate,
      id: "heightGate",
      addOn: inMm(uom),
      type: "number",
      disabled: data?.gateForm !== "rectangle",
      error: parseFloat(data?.heightGate) <= 0 &&
        "La altura de la compuerta debe de ser mayor a 0 (cero)"
    },
    {
      label: "Profundidad de Compuerta",
      value: data?.depthGate,
      id: "depthGate",
      addOn: inMm(uom),
      type: "number",
      disabled: data?.gateForm !== "rectangle",
      error: parseFloat(data?.depthGate) <= 0 &&
        "La profundidad de la compuerta debe de ser mayor a 0 (cero)"
    },
    {
      label: "Coeficiente de Corrección",
      value: data?.correctCoef,
      id: "correctCoef",
      addOn: "%",
      type: "number",
      error: parseFloat(data?.correctCoef) <= 0 &&
        "El Coeficiente de Corrección debe de ser mayor a 0 (cero)"
    },
    {
      label: "Máximo Shear Rate de Resina",
      value: data?.resinMaxShearRate,
      id: "resinMaxShearRate",
      addOn: "",
      type: "number",
      error: parseFloat(data?.resinMaxShearRate) <= 0 &&
        "El Máximo Shear Rate de Resina debe de ser mayor a 0 (cero)"
    },
    {
      label: "Tolerancia de Linealidad",
      value: data?.rangeLinearity,
      id: "rangeLinearity",
      addOn: "%",
      type: "number",
      error: parseFloat(data?.rangeLinearity) <= 0 &&
        "La Tolerancia de Linealidad debe de ser mayor a 0 (cero)"
    },
    {
      label: "Uso Deseado de Máquina",
      value: data?.maxMachineUse,
      id: "maxMachineUse",
      addOn: "%",
      type: "number",
      error: parseFloat(data?.maxMachineUse) >= 100 &&
        "El Uso Deseado de Máquina debe de ser menor a 100 (cien)"
    },
  ];
};
