import {
  hpsiToHbar,
  inMm,
  inSecMmsec,
  ozGr,
  temp,
} from "../../../hooks/useUomTransform";

export const inputData = (data) => {
  const { uom } = data?.header;
  return [
    {
      label: "Diámetro de Tornillo",
      value: data?.screwDiameter,
      id: "screwDiameter",
      addOn: inMm(uom),
      error:
        parseFloat(data?.screwDiameter) > parseFloat(data?.pistonDiameter) &&
        "El diámetro del tornillo, debe de ser menor que el diámetro del pistón",
      type: "number",
    },
    {
      label: "Diámetro de Pistón",
      value: data?.pistonDiameter,
      id: "pistonDiameter",
      addOn: inMm(uom),
      type: "number",
      error: parseFloat(data?.screwDiameter) > parseFloat(data?.pistonDiameter) &&
        "El diámetro del Pistón debe de ser igual o mayor que el diametro del tornillo"
    },
    {
      label: "Temperatura de Masa Real",
      value: data?.realMeltTemp,
      id: "realMeltTemp",
      addOn: temp(uom),
      type: "number",
      error: parseFloat(data?.realMeltTemp) < 0 &&
        "La temperatura de masa real debe de ser mayor a 0 (Cero)"

    },
    {
      label: "Máxima Velocidad de Inyección",
      value: data?.maxInjectionSpeed,
      id: "maxInjectionSpeed",
      addOn: inSecMmsec(uom),
      type: "number",
      error: parseFloat(data?.maxInjectionSpeed) < 0 &&
        "La Máxima velocidad de inyeccion debe de ser mayor a 0 (Cero)"
    },
    {
      label: "Tamaño de Disparo",
      value: data?.shotSizePosition,
      id: "shotSizePosition",
      addOn: inMm(uom),
      type: "number",
      error: parseFloat(data?.shotSizePosition) <= parseFloat(data?.cutoffPosition) &&
        "El tamaño de disparo debe de ser mayor a Posición de Transferencia V/P"
    },
    {
      label: "Posición de Transferencia V/P",
      value: data?.cutoffPosition,
      id: "cutoffPosition",
      addOn: inMm(uom),
      type: "number",
      error: parseFloat(data?.shotSizePosition) < parseFloat(data?.cutoffPosition) &&
        "La Posición de Transferencia V/P debe de ser menor al tamaño de disparo."
    },
    {
      label: "Tamaño de Descompresión",
      value: data?.decompressionSize,
      id: "decompressionSize",
      addOn: inMm(uom),
      type: "number",
      error: parseFloat(data?.decompressionSize) <= 0 &&
        "El Tamaño de Descompresión debe de ser mayor a 0 (Cero)"
    },
    {
      label: "Posición Teórica de Colchón",
      value: data?.cushionPositionTheoric,
      id: "cushionPositionTheoric",
      addOn: inMm(uom),
      type: "number",
      error: parseFloat(data?.cushionPositionTheoric) >= parseFloat(data?.cutoffPosition) &&
        "La Posición Teórica de Colchón debe de ser menor al tamaño de disparo."
    },
    {
      label: "Peso de Primera Etapa",
      value: data?.shotWeightFirstStage,
      id: "shotWeightFirstStage",
      addOn: ozGr(uom),
      type: "number",
      error: parseFloat(data?.shotWeightFirstStage) <= 0 &&
        "El Peso de Primera Etapa debe de ser mayor a 0 (Cero)"
    },
    {
      label: "Peso Específico",
      value: data?.specificWeight,
      id: "specificWeight",
      addOn: ":1",
      type: "number",
      error: parseFloat(data?.specificWeight) <= 0 &&
        "El Peso Específico debe de ser mayor a 0 (Cero)"
    },
    {
      label: "Máxima Presión de Inyección",
      value: data?.immMaxHPress,
      id: "immMaxHPress",
      addOn: hpsiToHbar(uom),
      type: "number",
      error: parseFloat(data?.immMaxHPress) <= 0 &&
        "La Máxima Presión de Inyección debe de ser mayor a 0 (Cero)"
    },
    {
      label: "Uso Deseado de Máquina",
      value: data?.maxMachineUse,
      id: "maxMachineUse",
      addOn: "%",
      type: "number",
      error: parseFloat(data?.maxMachineUse) > 100 &&
        "El Uso Deseado de Máquina debe de ser menor a 100 (Cien)"
    },
    {
      label: "Eficiencia Requerida",
      value: data?.processEfficence,
      id: "processEfficence",
      addOn: "%",
      type: "number",
      error: parseFloat(data?.processEfficence) > 100 &&
        "La Eficiencia Requerida debe de ser menor a 100 (Cien)"
    },
  ];
};
