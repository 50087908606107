import { Flex, Grid, Text } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { Link as ChakraLink } from "@chakra-ui/react";

import tecnicasplasticas from "../../assets/img/collaborators/tp/TCPL_LOGO_WHITE.avif";
import geaLogo from "../../assets/img/collaborators/gea/Logo-GEAB-Grises.png";
import gintecLogo from "../../assets/img/collaborators/gintec/GINTEC_LOGO_WHITE.avif";
import rediasaLogo from "../../assets/img/collaborators/rediassa/REDIASA_LOGO_WHITE.avif";
import asacleanLogo from "../../assets/img/collaborators/asaclean/ASACLEAN_LOGO_WHITE.avif";
import shelterLogo from "../../assets/img/collaborators/shelter/SHELTER_LOGO_WHITE.avif";
import ldLogo from "../../assets/img/collaborators/ld/LD_LOGO_WHITE.avif";
import hmsLogo from "../../assets/img/collaborators/hms/Logo-HMS-Grises.png";
import React, { lazy, Suspense, useEffect, useState } from "react";
import { Hub } from "aws-amplify/utils";
import { getCurrentUser } from "aws-amplify/auth";
import LoadingComponent from "../LoadingComponent";
const LazyImage = lazy(() => import("./../LazyImage")); // Asegúrate de usar la ruta correcta
const CollaboratorsFooter = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    checkAuthState();

    // Escuchar los cambios de estado de autenticación
    Hub.listen("auth", (data) => {
      const { payload } = data;
      if (payload.event === "signIn") {
        setIsAuthenticated(true);
      } else if (payload.event === "signOut") {
        setIsAuthenticated(false);
      }
    });
  }, []);

  const checkAuthState = async () => {
    try {
      await getCurrentUser();
      setIsAuthenticated(true);
    } catch (error) {
      setIsAuthenticated(false);
    }
  };

  return (
    <>
      <Flex direction="column" mx={8} mb="50px" justify="center" align="center">
        <Text
          color="#fff"
          fontWeight="bold"
          fontSize="3xl"
          mb="32px"
          maxW={{ sm: "250px", md: "100%" }}
          textAlign="center"
        >
          NUESTROS COLABORADORES
        </Text>
        {/* Logos */}
        <Grid
          templateColumns="repeat(8, 1fr)"
          gap={5}
          px={5}
          columns={{ sm: 2, md: 3, lg: 4 }}
          rows={{ sm: 3, md: 2, lg: 1 }}
          spacingX={{ sm: "65px", lg: "40px", xl: "15px" }}
          spacingY={{ sm: "30px" }}
          alignItems={"center"}
        >
          <ChakraLink
            as={RouterLink}
            aria-label="Lee mas acerca de Tecnicas Plasticas"
            to={`/${
              isAuthenticated ? "admin" : "banner"
            }/collaborators/tecnicas-plasticas`}
          >
            <Suspense fallback={<LoadingComponent />}>
              <LazyImage
                alt={"tecnicas-plasticas"}
                src={tecnicasplasticas}
                alignSelf="center"
                justifySelf="center"
              />
            </Suspense>
          </ChakraLink>
          <ChakraLink
            as={RouterLink}
            aria-label="Lee mas acerca de Gea"
            to={`/${isAuthenticated ? "admin" : "banner"}/collaborators/gea`}
          >
            <Suspense fallback={<LoadingComponent />}>
              <LazyImage
                alt={"gea"}
                src={geaLogo}
                alignSelf="center"
                justifySelf="center"
              />
            </Suspense>
          </ChakraLink>
          <ChakraLink
            as={RouterLink}
            aria-label="Lee mas acerca de Gintec"
            to={`/${isAuthenticated ? "admin" : "banner"}/collaborators/gintec`}
          >
            <Suspense fallback={<LoadingComponent />}>
              <LazyImage
                alt={"gintec"}
                src={gintecLogo}
                alignSelf="center"
                justifySelf="center"
              />
            </Suspense>
          </ChakraLink>
          <ChakraLink
            as={RouterLink}
            aria-label="Lee mas acerca de Somos Shelter"
            to={`/${
              isAuthenticated ? "admin" : "banner"
            }/collaborators/shelter`}
          >
            <Suspense fallback={<LoadingComponent />}>
              <LazyImage
                alt={"somos-shelter"}
                src={shelterLogo}
                alignSelf="center"
                justifySelf="center"
              />
            </Suspense>
          </ChakraLink>
          <ChakraLink
            as={RouterLink}
            aria-label="Lee mas acerca de Rediasa"
            to={`/${
              isAuthenticated ? "admin" : "banner"
            }/collaborators/rediassa`}
          >
            <Suspense fallback={<LoadingComponent />}>
              <LazyImage
                alt={"rediassa"}
                src={rediasaLogo}
                alignSelf="center"
                justifySelf="center"
              />
            </Suspense>
          </ChakraLink>
          <ChakraLink
            as={RouterLink}
            aria-label="Lee mas acerca de Asa Clean"
            to={`/${
              isAuthenticated ? "admin" : "banner"
            }/collaborators/asaclean`}
          >
            <Suspense fallback={<LoadingComponent />}>
              <LazyImage
                alt={"asa-clean"}
                src={asacleanLogo}
                alignSelf="center"
                justifySelf="center"
              />
            </Suspense>
          </ChakraLink>
          <ChakraLink
            as={RouterLink} // Usa ChakraLink como un wrapper para RouterLink
            to={`/${
              isAuthenticated ? "admin" : "banner"
            }/collaborators/ld-plastic-solutions`}
            aria-label="Lee mas acerca de LD Plastic Solutions"
          >
            <Suspense fallback={<LoadingComponent />}>
              <LazyImage
                alt={"ld-plastic-solutions"}
                src={ldLogo}
                alignSelf="center"
                justifySelf="center"
              />
            </Suspense>
          </ChakraLink>
          <ChakraLink
            as={RouterLink}
            aria-label="Lee mas acerca de Hms"
            to={`/${isAuthenticated ? "admin" : "banner"}/collaborators/hms`}
          >
            <Suspense fallback={<LoadingComponent />}>
              <LazyImage
                alt={"hms"}
                src={hmsLogo}
                alignSelf="center"
                justifySelf="center"
              />
            </Suspense>
          </ChakraLink>
        </Grid>
      </Flex>
    </>
  );
};

export default CollaboratorsFooter;
