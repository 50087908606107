import CustomGrid from "../../../components/CustomGrid";
import CustomInput from "../../../components/CustomInput";
import { inputData } from "../utilities/inputData";
import { Button, GridItem } from "@chakra-ui/react";
import { useVerifyForm } from "../../../hooks/useVerifyForm";
import { keysForForm } from "../utilities/variables";
import {useEffect} from "react";

const Form = ({ onChange, data, disabled, onSubmit, isLoading, showDataTable, setShowDataTable, setShowchart }) => {
  const isDisabled = !useVerifyForm(data, keysForForm);

  const keys = () => {
    if (data.gateForm === "circle") {
      return ["gateDiameter"]
    } else {
     return [
        "widthGate",
        "heightGate",
        "depthGate",
      ]
    }
  }
  const isExtraDataFilled = () => {
    return useVerifyForm(data, keys())
  }

  const onEditHandler = () => {
    setShowDataTable(false)
    setShowchart(false)
  }

  useEffect(() => {
    isValidForm()
  }, []);

  const isValidForm = () => {
    let isValid = true
    inputData(data)?.map(item => {
      if (item.error) {
        isValid = false;
      }
    })

    return isValid
  }

  return (
    <CustomGrid repeat={4}>
      {inputData(data)?.map((item, index) => {
        if (!item.disabled) {
          return (
            <CustomInput
              disabled={disabled || showDataTable}
              key={`${item.id}-${index}-${item.addOn}-text`}
              addOnText={item.addOn}
              label={item.label}
              value={item.value}
              id={item.id}
              onChange={onChange}
              error={item.error}
              type={item.type}
              max={item.max}
              item={item}
            />
          );
        }

      })}
      <GridItem colSpan={4}>
        <Button
          isLoading={isLoading}
          onClick={showDataTable ? () => onEditHandler(false) : () => onSubmit()}
          colorScheme={"facebook"}
          disabled={isDisabled || isLoading || !isValidForm() || !isExtraDataFilled()}
        >
          {showDataTable ? "Editar" : "Continuar"}
        </Button>
      </GridItem>
    </CustomGrid>
  );
};

export default Form;
