import { useContext, useEffect } from "react";
import { useState } from "react";
import { viscoscityStudyDataMatrixInitialState } from "../utilities/variables";
import ProjectContext from "../../../../../../../contexts/ProjectContext";
import { Project } from "../../../../../../../models";
import { DataStore } from "aws-amplify/datastore";
import { post } from "aws-amplify/api";
import { textColor } from "../../../utilities/variables";

export const useViscoscityStudyHooks = () => {
  const [showChart, setShowChart] = useState(false);
  const [showData, setShowData] = useState(false);
  const [isDisabledButtonDataMatrix, setIsDisabledButtonDataMatrix] = useState(
    false
  );
  const [dataChart, setDataChart] = useState([]);
  const [shearDataChart, setShearDataChart] = useState([]);
  const [shearDataBarChart, setShearDataBarChart] = useState([]);
  const [relativeViscoscity, setRelativeViscoscity] = useState([]);

  const { project, updateProject, machine, updateLoading } = useContext(
    ProjectContext
  );

  const rebuildShearData = (sequenceValue) => {
    let data = [];
    const arraySize = 10;
    const maxValue = Math.max(...relativeViscoscity);

    for (let i = 0; i < arraySize; i++) {
      if (arraySize - i === parseInt(sequenceValue)) {
        data.unshift(maxValue); // Coloca el valor máximo en la posición correcta
      } else {
        data.unshift(0); // Coloca un 0 en las demás posiciones
      }
    }
    data = data.reverse();

    return data;
  };

  useEffect(() => {
    if (project.sequence_Selection && setRelativeViscoscity) {
      const data = rebuildShearData(project.sequence_Selection);
      setShearDataBarChart(data);
    }
  }, [project.sequence_Selection, relativeViscoscity]);

  const onChangeSequenceHandler = async (e) => {
    setShowChart(false);
    updateLoading(true);
    let data = [];
    const arraySize = 10;
    const maxValue = Math.max(...relativeViscoscity);

    for (let i = 1; i < arraySize; i++) {
      if (i === arraySize - parseInt(e.value) + 1) {
        //console.log(arraySize - parseInt(e.value) + 1);
        data.unshift(maxValue); // Coloca el valor máximo en la posición correcta
      } else {
        data.unshift(0); // Coloca un 0 en las demás posiciones
      }
    }
    data = data.reverse();

    const p = await DataStore.query(Project, project.id);
    await DataStore.save(
      Project.copyOf(p, (updated) => {
        updated.sequence_Selection = e.value.toString();
        updated.phase = "9";
      })
    )
      .then(async (res) => {
        await updateProject(res);
        await setShearDataBarChart(data);
        setShowChart(true);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => updateLoading(false));
  };

  useEffect(() => {
    if (viscoscityStudyDataMatrix) {
      const data = viscoscityStudyDataMatrix
        .slice(0)
        .reverse()
        .map((item) => {
          return item.maxPressurePpsi;
        });
      const data2 = viscoscityStudyDataMatrix
        .slice(0)
        .reverse()
        .map((item) => {
          return item.shearRate;
        });
      const data3 = viscoscityStudyDataMatrix
        .slice(0)
        .reverse()
        .map((item) => {
          return item.relativeViscocity;
        });
      setDataChart(data);
      setShearDataChart(data2);
      setRelativeViscoscity(data3);
    }
  }, [project]);

  const lineChartOptionsMatrix = {
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
    },
    chart: {
      height: 350,
      type: "line",
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      show: false,
      enabled: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "datetime",
      categories: shearDataChart,
      labels: {
        style: {
          colors: textColor,
          fontSize: "12px",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: [
      {
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: false,
          color: textColor,
        },
        labels: {
          show: true,
          style: {
            colors: textColor,
            fontSize: "12px",
          },
        },
        title: {
          show: false,
          text: "",
          style: {
            color: "#008FFB",
          },
        },
        tooltip: {
          enabled: false,
          show: false,
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 0,
        borderRadiusApplication: "around",
        borderRadiusWhenStacked: "last",
        columnWidth: "5%",
      },
    },
    legend: {
      show: false,
      color: "white",
    },
    grid: {
      show: false,
      strokeDashArray: 0,
      borderColor: "#FEB019",
    },
    colors: ["#053ecc", "#008FFB", "#00FF00"],
  };
  //colors: ["#56577A", "#008FFB", "#FEB019"],

  const lineChartDataMatrix = [
    {
      name: "Presión Máxima Ppsi",
      type: "line",
      data: dataChart,
    },
    {
      name: "Presión Máxima Ppsi",
      type: "line",
      data: relativeViscoscity,
    },
    {
      name: "Presión Máxima Ppsi",
      type: "bar",
      data: shearDataBarChart,
    },
  ];

  const { maxInjectionSpeed } = machine;
  const {
    maxPlasticPressure,
    screwArea,
    shotSizePositionTheoric,
    decompressionSize,
    intRatio,
    speed_Interval_Unit,
    max_Machine_Use,
    viscoscityStudyDataMatrix,
    uom
  } = project;
  const onChangeHandler = (e) => {
    updateProject({ ...project, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (!project.viscoscityStudyDataMatrix) {
      updateProject({
        ...project,
        viscoscityStudyDataMatrix: viscoscityStudyDataMatrixInitialState,
      });
    }

    if (viscoscityStudyDataMatrix) {
      viscoscityStudyDataMatrix.map((item) => {
        if (
          !item.fillingTime ||
          !item.maxPressureHpsi ||
          !item.shotWeight ||
          !item.vpCuttingPosition
        ) {
          setIsDisabledButtonDataMatrix(true);
        } else {
          setIsDisabledButtonDataMatrix(false);
        }
      });
    }
  }, [project]);

  const onSubmitGenerateDataHandler = async () => {
    updateLoading(true);

    const restOperation = post({
      apiName: "viscoscityStudy",
      path: "/viscoscity-study-first",
      options: {
        body: {
          max_Machine_Use,
          maxInjectionSpeed,
          maxPlasticPressure,
        },
      },
    });

    const rest = await restOperation.response;
    const res = await rest.body.json();

    const { speed_Interval_Unit, max_ppsi_used } = res;

    let temp = 10;

    const updatedViscoscityStudyDataMatrix = viscoscityStudyDataMatrix.map(
      (item) => {

        const newIntervalSpeed = speed_Interval_Unit * temp;
        temp -= 1;

        return {
          ...item,
          intervalSpeed: newIntervalSpeed.toFixed(4),
        };
      }
    );
    const p = await DataStore.query(Project, project.id);
    await DataStore.save(
      Project.copyOf(p, (updated) => {
        updated.speed_Interval_Unit = speed_Interval_Unit.toString();
        updated.max_ppsi_used = max_ppsi_used.toString();
        updated.max_Machine_Use = max_Machine_Use.toString();
        updated.viscoscityStudyDataMatrix = updatedViscoscityStudyDataMatrix;
      })
    )
      .then((res) => {
        setShowData(true);
        updateProject(res);
      })
      .finally(() => updateLoading(false));
  };
  const onChangeDataMatrix = (e, id) => {
    const p = viscoscityStudyDataMatrix.map((item) => {
      if (item.sequence === id) {
        return { ...item, [e.target.name]: e.target.value };
      } else {
        return item;
      }
    });
    updateProject({ ...project, viscoscityStudyDataMatrix: p });
  };

  const onSubmitMatrix = async () => {
    updateLoading(true);

    const restOperation = post({
      apiName: "viscoscityStudy",
      path: "/viscoscity-study-matrix",
      options: {
        body: {
          uom,
          viscoscityStudyDataMatrix,
          speed_Interval_Unit,
          intRatio,
          screwArea,
          shotSizePositionTheoric,
          decompressionSize,
        },
      },
    });
    const rest = await restOperation.response;
    const res = await rest.body.json();
    const p = await DataStore.query(Project, project.id);

    await DataStore.save(
      Project.copyOf(p, (updated) => {
        updated.viscoscityStudyDataMatrix = res.viscoscityStudyDataMatrix;
      })
    )
      .then(async (res) => {
        await updateProject(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        updateLoading(false);
        setShowChart(true);
      });
  };

  return {
    showData,
    onSubmitGenerateDataHandler,
    isDisabledButtonDataMatrix,
    onSubmitMatrix,
    setShowChart,
    showChart,
    lineChartDataMatrix,
    lineChartOptionsMatrix,
    onChangeSequenceHandler,
    onChangeDataMatrix,
    setShowData,
  };
};
