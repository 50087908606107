import {inCubeMmCube, inMm, inSecMmsec, inSquareMmSquare, ozGr, ppsiToPbar} from "../../../../../hooks/useUomTransform";

export const dataOutputs1 = data => {
  return [
    {
      label: "Tamaño Total de Disparo",
      value: data?.overallShotSize,
      addOn: inMm(data?.header?.uom),
      type: "",
    },
    {
      label: "Área de Pistón",
      value: data?.pistonArea,
      addOn: inSquareMmSquare(data?.header?.uom),
      type: "",
    },
    {
      label: "Volumen de Disparo",
      value: data?.shotVolume,
      addOn: inCubeMmCube(data?.header?.uom),
      type: "",
    },
    {
      label: "MAX Presión Requerida",
      value: data?.maxPpsiUsed,
      addOn: ppsiToPbar(data?.header?.uom),
      type: "separated",
    },
  ];
}

export const dataOutputs2 = data => {
  return [
    {
      label: "Área de Tornillo",
      value: data?.screwArea,
      type: "",
      addOn: inSquareMmSquare(data?.header?.uom),
    },
    {
      label: "Razón de Intensificación",
      value: data?.intRatio,
      type: "int",
      addOn: ":1",
    },
    {
      label: "Máxima Presión Plástica",
      value: data?.maxPlasticPressure,
      type: "separated",
      addOn: ppsiToPbar(data?.header?.uom),
    },
    {
      label: "Intervalos de Velocidad",
      value: data?.speedIntervalUnit,
      type: "",
      addOn: inSecMmsec(data?.header?.uom),
    },
  ];
}
