import {useHeader} from "../../../../../../contexts/IndividualContext/HeaderContext";
import {useContext, useEffect, useRef, useState} from "react";
import {convertNumbersToString, isObjectEmpty} from "../../../../../../utilities/functions";
import {initialState} from "../utilities/variables";
import {useAlert} from "../../../../../../contexts/useAlert";
import {textColor} from "../../../../project-overview/components/utilities/variables";
import {selectOptions} from "../../../../project-overview/components/tools/gate-sealing/utilities/variables";
import {useIndividualGlobalHooks} from "../../useIndividualGlobalHooks";
import {uploadData} from "aws-amplify/storage";
import UserContext from "../../../../../../contexts/UserContext";
import conditionalsAlert from "../../../../hooks/ConditionalsAlert";
import {DataStore} from "aws-amplify/datastore";
import {LinearityStudy} from "../../../../../../models";

export const useIndividualHooks = (setIsDisabled) => {
  const {
    uom,
    resinName,
    toolName,
    machineName,
    productName,
    header,
  } = useHeader();

  const [data, setData] = useState(initialState);
  const [result, setResult] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showDataTable, setShowDataTable] = useState(false);
  const { showErrorAlert } = useAlert();
  const chartRef = useRef();
  const [isTableFormComplete, setIsTableFormComplete] = useState(false);
  const [showChart, setShowchart] = useState(false);
  const [shearDataBarChart, setShearDataBarChart] = useState([]);
  const [shearDataBarChart2, setShearDataBarChart2] = useState([]);
  const [dataChart, setDataChart] = useState([]);
  const [realSpeedChart, setRealSpeedChart] = useState([]);

  const { createImages } = useIndividualGlobalHooks();
  const { user } = useContext(UserContext);

  useEffect(() => {
    onchangeShearDataBarHandler();
  }, [data.lineHighLimit, data.lineLowLimit]);

  const onchangeShearDataBarHandler = () => {
    setShowchart(false);
    console.log(data);
    if (parseInt(data.lineLowLimit) >= parseInt(data.lineHighLimit)) {
      setData({
        ...data,
        lineHighLimit: (parseInt(data.lineLowLimit) + 1).toString(),
      });
    }
    if (data.lineLowLimit) {
      const sequence = rebuildShearData(data.lineLowLimit);
      const maxSequence = rebuildShearData(data.lineHighLimit);
      setShearDataBarChart(sequence);
      console.log(sequence, maxSequence);
      setShearDataBarChart2(maxSequence);
      setTimeout(() => {
        if (isTableFormComplete) {
          setShowchart(true);
        }
      }, 100);
    }
  };

  const maxSequenceOptions = selectOptions.filter(
    (option) => parseInt(option.value) > parseInt(data.lineLowLimit)
  );

  const onChangeMaxSelectedSequence = (event) => {
    setData({
      ...data,
      lineHighLimit: event.value,
    });
  };

  const rebuildShearData = (sequenceValue) => {
    let data = [];
    const arraySize = 10;
    const maxValue = parseFloat(Math.max(...realSpeedChart).toFixed(3));

    for (let i = 0; i < arraySize; i++) {
      if (arraySize - i === parseInt(sequenceValue)) {
        data.unshift(maxValue); // Coloca el valor máximo en la posición correcta
      } else {
        data.unshift(null); // Coloca un 0 en las demás posiciones
      }
    }
    data = data.reverse();

    return data;
  };

  useEffect(() => {
    if (!isObjectEmpty(result)) {
      setIsDisabled(true);
    }
  }, [result]);

  useEffect(() => {
    // Actualiza `data` para incluir el `header` actualizado
    setData((prevData) => ({
      ...prevData,
      header,
    }));
  }, [header]);

  const onChangeHandler = (e) => {
    const { id, value } = e.target;

    if (id.includes(".")) {
      const [outerKey, innerKey] = id.split(".");

      setData((prevData) => ({
        ...prevData,
        [outerKey]: {
          ...prevData[outerKey],
          [innerKey]: value,
        },
      }));
    } else {
      setData((prevData) => ({
        ...prevData,
        [id]: value,
      }));
    }
  };

  const getViscAvg = () => {
    return (
      data.table.reduce((sum, item) => {
        const realViscosity =
          parseFloat(item.fillTime) *
          (data.intRatio * parseFloat(item.maxPress));
        return sum + realViscosity;
      }, 0) / data.table.length
    );
  };

  const getMaxShearRate = (item) => {
    const shotVolumeFactor = data.header.uom === "INGLES" ? 1 : 1000;
    if (data.gateForm === "circle") {
      return (
        ((4 *
          ((parseFloat(data.shotVolume) * shotVolumeFactor) /
            parseFloat(data.numberOfCavities))) /
          parseFloat(item.fillTime) /
          (Math.PI * Math.pow(parseFloat(data.gateDiameter) / 2, 3))) *
        (1 + parseFloat(data.correctCoef) / 100)
      );
    } else {
      return (
        ((6 *
          ((parseFloat(data.shotVolume) * shotVolumeFactor) /
            parseFloat(data.numberOfCavities))) /
          parseFloat(item.fillTime) /
          (parseFloat(data.widthGate) *
            parseFloat(data.heightGate) *
            parseFloat(data.depthGate))) *
        (1 + parseFloat(data.correctCoef) / 100)
      );
    }
  };

  const fillTable = async () => {
    try {
      const viscosityAvg = await getViscAvg();

      const newData = data?.table?.map((item) => {
        const maxShearRate = getMaxShearRate(item);
        const maxPress2 = parseFloat(item.maxPress * data.intRatio);

        const realViscosity = parseFloat(
          item.fillTime * (data.intRatio * item.maxPress)
        )
          .toFixed(3)
          .toString();

        return {
          ...item,
          realSpeed: parseFloat(
            (
              (parseFloat(data.shotSizePosition) +
                parseFloat(data.decompressionSize) -
                parseFloat(item.vpTransfer)) /
              parseFloat(item.fillTime)
            ).toFixed(3)
          ).toString(),
          realViscosity,
          maxPress2,
          reologhy: realViscosity / viscosityAvg,
          linearity:
            (100 *
              ((parseFloat(data.shotSizePosition) +
                parseFloat(data.decompressionSize) -
                parseFloat(item.vpTransfer)) /
                parseFloat(item.fillTime) -
                parseFloat(item.speed))) /
            parseFloat(item.speed),
          maxShearRate,
        };
      });

      // Actualiza el estado con la tabla nueva
      setData((prevData) => ({
        ...prevData,
        table: newData, // Asegúrate de que `newData` sea la nueva tabla
      }));

      setIsTableFormComplete(true);

      const renewData = newData
        .slice(0)
        .reverse()
        .map((item) => {
          return parseFloat(item.realSpeed).toFixed(2);
        });
      setDataChart(renewData);
      setTimeout(() => {
        setShowchart(true);
      }, 500)

    } catch (error) {
      console.log(error);
    }
  };

  const onChangeSequenceHandler = (e) => {
    try {
      setData({
        ...data,
        lineLowLimit: e.value.toString(),
      });
    } catch (e) {
      console.log(e);
    }
  };

  const updateTableWithSpeed = (table, speedIntervalUnit) => {
    return table.map((row) => ({
      ...row,
      speed: (speedIntervalUnit * (11 - parseInt(row.sequence))).toFixed(3),
    }));
  };

  const onSubmitHandler = async () => {
    setIsLoading(true);
    console.log("submit");
    try {
      const pistonArea =
        parseFloat(data.pistonDiameter) *
        parseFloat(data.pistonDiameter) *
        0.785;
      const screwArea =
        parseFloat(data.screwDiameter) * parseFloat(data.screwDiameter) * 0.785;

      const speedIntervalUnit =
        (parseFloat(data.maxInjectionSpeed) *
          (parseFloat(data.maxMachineUse) / 100)) /
        10;

      const updatedTable = updateTableWithSpeed(data?.table, speedIntervalUnit);
      const dataSpeed = updatedTable
        .slice(0)
        .reverse()
        .map((item) => {
          return item.speed;
        });
      console.log(dataSpeed);
      setRealSpeedChart(dataSpeed);

      const intRatio = pistonArea / screwArea;

      const maxPlasticPressure = parseFloat(data.immMaxHPress) * intRatio;

      setData({
        ...data,
        overallShotSize:
          parseFloat(data.shotSizePosition) -
          parseFloat(data.cushionPositionTheoric),
        screwArea,
        pistonArea,
        intRatio,
        shotVolume:
          data.header.uom === "INGLES"
            ? parseFloat(data.shotWeight100) /
              parseFloat(data.specificWeight) /
              0.554113
            : parseFloat(data.shotWeight100) / parseFloat(data.specificWeight),
        maxPlasticPressure,
        maxPpsiUsed:
          maxPlasticPressure * (parseFloat(data.maxMachineUse) / 100),
        speedIntervalUnit,
        table: updatedTable,
      });
      setShowDataTable(true);
    } catch (error) {
      console.log(error);
      const e = error.message ? error.message : error;
      showErrorAlert(e);
    } finally {
      setIsLoading(false);
    }
  };

  const onChangeTable = (e) => {
    const { name, value } = e.target;
    const [outerKey, index, innerKey] = name.split(".");

    if (innerKey) {
      // Manejar cambio para propiedades dentro de arrays (ej. data.table[0].speed)
      setData((prevData) => ({
        ...prevData,
        [outerKey]: prevData[outerKey].map((item, idx) =>
          idx.toString() === index ? { ...item, [innerKey]: value } : item
        ),
      }));
    } else {
      // Manejar cambio para propiedades de nivel superior
      setData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const onFinishHandler = async () => {
    setIsLoading(true);
    const image = await createImages(chartRef);
    const res = await uploadData({
      key: `llinearity-study-chart-individual/${Date.now()}.png`,
      data: image,
    }).result;

    const stringData = await convertNumbersToString(data);
    //stringData.table = await convertNumbersToString(data.table)

    console.log(stringData)
    try {
      const response = await DataStore.save(
        new LinearityStudy({
          ...stringData,
          chartImage: res.key,
          userID: user.id,
        })
      );

      console.log(response)

      let text = "";
      let icon = "success";

      const table = response.table;

      const allMatchMaxPress = table.every(
        (row) => parseFloat(row.maxPress2) >= parseFloat(data.maxPpsiUsed)
      );

      const allMatchReologhy = table.every(
        (row) => parseFloat(row.reologhy) >= 1
      );

      const allMatchLinearity = table.every(
        (row) => parseFloat(row.linearity) < (parseFloat(data.rangeLinearity)* (- 1))
      );
      const allMatchMaxShearRate = table.every(
        (row) => parseFloat(row.maxShearRate) >= (parseFloat(data.resinMaxShearRate))
      );

      if (allMatchMaxPress || allMatchReologhy || allMatchLinearity || allMatchMaxShearRate) {
        text +=
          "<li>El proceso necesita ser realizado nuevamente por valores criticos fuera de rango</li>";
        icon = "warning";
      }

      if (allMatchMaxPress) {
        text +=
          "<li>Error en Máxima presion</li>";
      }
      if (allMatchReologhy) {
        text +=
          "<li>Error en Reología</li>";
        icon = "warning";
      }

      if (allMatchLinearity) {
        text +=
          "<li>Error en Linealidad</li>";
        icon = "warning";
      }
      if (allMatchMaxShearRate) {
        text +=
          "<li>Error en MAX Shear Rate</li>";
        icon = "warning";
      }
      setResult(response);
      conditionalsAlert(icon, text);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const chartData = [
    {
      name: "Velocidad Real",
      type: "line",
      data: dataChart,
    },
    {
      name: "Velocidad",
      type: "line",
      data: realSpeedChart,
    },
    {
      name: "Secuencia Mínima",
      type: "bar",
      data: shearDataBarChart,
    },
    {
      name: "Secuencia Máxima",
      type: "bar",
      data: shearDataBarChart2,
    },
  ];

  const chartOptions = {
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
    },
    chart: {
      height: 350,
      type: "line",
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      show: false,
      enabled: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },
    xaxis: {
      type: "datetime",
      categories: realSpeedChart,
      labels: {
        style: {
          colors: textColor,
          fontSize: "12px",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: [
      {
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: false,
          color: textColor,
        },
        labels: {
          show: true,
          style: {
            colors: textColor,
            fontSize: "12px",
          },
        },
        title: {
          show: false,
          text: "",
          style: {
            color: "#008FFB",
          },
        },
        tooltip: {
          enabled: false,
          show: false,
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 0,
        //borderRadiusApplication: "around",
        columnWidth: "5%",
      },
    },
    legend: {
      show: true,
      color: "white",
    },
    grid: {
      show: false,
      strokeDashArray: 0,
      borderColor: "#FEB019",
    },
    colors: ["#053ecc", "#008FFB", "#166534", "#166534"],
  };

  return {
    uom,
    toolName,
    resinName,
    productName,
    machineName,
    data,
    onChangeHandler,
    onSubmitHandler,
    isLoading,
    result,
    showDataTable,
    onChangeTable,
    isTableFormComplete,
    fillTable,
    showChart,
    setShowchart,
    onChangeSequenceHandler,
    onChangeMaxSelectedSequence,
    maxSequenceOptions,
    setShowDataTable,
    chartRef,
    onFinishHandler,
    chartOptions,
    chartData,
  };
};
