// usePdfStyles.js
import { StyleSheet } from "@react-pdf/renderer";

const usePdfStyles = () => {
  return StyleSheet.create({
    table: {
      display: "table",
      width: "90%",
      marginVertical: 10,
      borderCollapse: "collapse",
      justifyContent: "space-between",
      marginLeft: "5%",
      marginRight: "5%",
      borderRadius: 10,
      border: 1
    },
    tableHeader: {
      flexDirection: "row",
      backgroundColor: "#f2f2f2",
      padding: 5,
      borderBottom: "1px solid #000",
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
    },
    tableRow: {
      flexDirection: "row",
      padding: 5,
      borderBottom: "1px solid #ddd",
    },
    cell: {
      flex: 1,
      fontSize: 8,
      padding: 4,
      borderRight: "1px solid #ddd",
      textAlign: "center",
    },
    headerCell: {
      fontWeight: "bold",
    },
    headerTable: {
      fontSize: 14,
      fontWeight: "bold",
      marginBottom: 10,
      textAlign: "center",
    },
    body: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35,
    },
    title: {
      fontSize: 24,
      textAlign: "center",
      fontFamily: "Oswald",
    },
    author: {
      fontSize: 12,
      textAlign: "center",
      marginBottom: 3,
    },
    subtitle: {
      position: "absolute",
      fontSize: 18,
      margin: 12,
      fontFamily: "Oswald",
    },
    text: {
      marginBottom: 2,
      marginHorizontal: 20,
      fontSize: 10,
      textAlign: "justify",
      fontFamily: "Times-Roman",
      marginTop: 2,
    },
    image: {
      marginVertical: 15,
      marginHorizontal: 20,
      width: "50%",
      opacity: .7
    },
    header: {
      fontSize: 18,
      marginBottom: 10,
      textAlign: "center",
      color: "grey",
    },
    page: {
      paddingTop: 35,
      alignItems: "center",
      paddingBottom: 65, // Espacio suficiente para el pie de página
      paddingHorizontal: 35,
      minHeight: "100%", // Asegúrate de que la página tenga una altura mínima
      // ...otros estilos...
    },
    footer: {
      position: "absolute",
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "grey",
    },
    pageNumber: {
      position: "absolute",
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "grey",
    },
    view: {
      border: 1,
      width: "90%",
      padding: "10px",
      marginLeft: "5%",
      marginRight: "5%",
      marginBottom: "20px",
      borderRadius: 10,
      justifyContent: "space-between",
      flexDirection: "row",
    },
    viewContainer: {
      border: 1,
      width: "90%",
      padding: "10px",
      marginLeft: "5%",
      marginRight: "5%",
      marginBottom: "20px",
      borderRadius: 10,
      justifyContent: "space-between",
      flexDirection: "column",
    },
    // ...otros estilos que necesites...
  });
};

export default usePdfStyles;
