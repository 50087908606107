export const isObjectEmpty = (obj) => {
  return Object.keys(obj).length === 0;
};

export const isFloatString = (str) => {
  if (str === "") return true; // Permite cadena vacía como válida
  if (str === null || str === undefined) return false;

  const regex = /^-?\d*\.?\d*$/; // Permite dígitos opcionales antes y después del punto decimal
  return regex.test(str);
};

export const isFloatStringPositive = (str) => {
  if (str === "") return true; // La cadena vacía ya no es válida
  if (str === null || str === undefined) return false;

  const regex = /^\d*\.?\d*$/;  // Solo permite dígitos positivos con al menos un dígito antes o después del punto decimal
  return regex.test(str);
};

export const validateMinMax = (str, min, max) => {
  if (str === "") return true; // Permite cadena vacía como válida
  if (str === null || str === undefined) return false;

  const regex = /^-?\d*\.?\d*$/; // Permite dígitos opcionales antes y después del punto decimal
  if (parseFloat(str) > parseFloat(max)) return false
  return regex.test(str);
}

export const areAllFloats = (values) => {
  return values.every(isFloatString);
};

export const  convertNumbersToString = (data) =>{
  // Crear una copia del objeto para no modificar el original
  if (Array.isArray(data)) {
    // Si es un array, mapea cada elemento y llama a la función recursivamente
    return data.map((item) => convertNumbersToString(item));
  } else if (typeof data === "object" && data !== null) {
    // Si es un objeto, iterar por cada clave y procesar sus valores
    const convertedObject = {};
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        convertedObject[key] = convertNumbersToString(data[key]);
      }
    }
    return convertedObject;
  } else if (typeof data === "number") {
    // Convertir números a string
    return data.toString();
  }
  return data; // Retornar el valor sin cambios si no es número u objeto
}
