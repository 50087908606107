import {inCubeMmCube, inMm, inSecMmsec, inSquareMmSquare, ozGr, ppsiToPbar} from "../../../../../hooks/useUomTransform";

export const dataOutputs1 = data => {
  return [
    {
      label: "Área de Tornillo",
      value: data?.screwArea,
      addOn: inSquareMmSquare(data?.header?.uom),
      type: "",
    },
    {
      label: "Tamaño Total de Disparo",
      value: data?.overallShotSize,
      addOn: inMm(data?.header?.uom),
      type: "",
    },
    {
      label: "Máxima Presión Plástica",
      value: data?.maxPlasticPress,
      addOn: ppsiToPbar(data?.header?.uom),
      type: "",
    },
    {
      label: "MAX Presión Requerida",
      value: data?.maxPressReq,
      addOn: ppsiToPbar(data?.header?.uom),
      type: "separated",
    },
  ];
}

export const dataOutputs2 = data => {
  return [
    {
      label: "Área de Pistón",
      value: data?.pistonArea,
      type: "",
      addOn: inSquareMmSquare(data?.header?.uom),
    },
    {
      label: "Razón de Intensificación",
      value: data?.intRatio,
      type: "int",
      addOn: ":1",
    },
    {
      label: "Intervalos de Velocidad",
      value: data?.speedIntervalUnit,
      type: "",
      addOn: inSecMmsec(data?.header?.uom),
    },
  ];
}
