import { useHeader } from "../../../../../../contexts/IndividualContext/HeaderContext";
import { useContext, useEffect, useRef, useState } from "react";
import { isObjectEmpty } from "../../../../../../utilities/functions";
import { post } from "aws-amplify/api";
import { initialState } from "../utilities/variables";
import { useAlert } from "../../../../../../contexts/useAlert";
import { updateTableSpeeds } from "../utilities/updateTableSpeeds";
import { textColor } from "../../../../project-overview/components/utilities/variables";
import { selectOptions } from "../../../../project-overview/components/tools/gate-sealing/utilities/variables";
import { useIndividualGlobalHooks } from "../../useIndividualGlobalHooks";
import { uploadData } from "aws-amplify/storage";
import UserContext from "../../../../../../contexts/UserContext";
import conditionalsAlert from "../../../../hooks/ConditionalsAlert";
import { DataStore } from "aws-amplify/datastore";
import {  ViscosityStudy } from "../../../../../../models";

export const useIndividualHooks = (setIsDisabled) => {
  const {
    uom,
    resinName,
    toolName,
    machineName,
    productName,
    header,
  } = useHeader();

  const [data, setData] = useState(initialState);
  const [result, setResult] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showDataTable, setShowDataTable] = useState(false);
  const {  showErrorAlert } = useAlert();
  const chartRef = useRef();
  const [isTableFormComplete, setIsTableFormComplete] = useState(false);
  const [showChart, setShowchart] = useState(false);
  const [shearDataChart, setShearDataChart] = useState([]);
  const [shearDataBarChart, setShearDataBarChart] = useState([]);
  const [shearDataBarChart2, setShearDataBarChart2] = useState([]);
  const [relativeViscoscity, setRelativeViscoscity] = useState([]);
  const [dataChart, setDataChart] = useState([]);
  const { createImages } = useIndividualGlobalHooks();
  const { user } = useContext(UserContext);

  useEffect(() => {
    onchangeShearDataBarHandler();
  }, [data.selectedSequence, data.maxSelectedSequence, relativeViscoscity]);

  const onchangeShearDataBarHandler = () => {
    setShowchart(false);
    if (parseInt(data.selectedSequence) >= parseInt(data.maxSelectedSequence)) {
      setData({
        ...data,
        maxSelectedSequence: (parseInt(data.selectedSequence) + 1).toString(),
      });
    }
    if (data.selectedSequence) {
      const sequence = rebuildShearData(data.selectedSequence);
      const maxSequence = rebuildShearData(data.maxSelectedSequence);
      setShearDataBarChart(sequence);
      setShearDataBarChart2(maxSequence);
      setTimeout(() => {
        if (isTableFormComplete) {
          setShowchart(true);
        }
      }, 100);
    }
  };

  const maxSequenceOptions = selectOptions.filter(
    (option) => parseInt(option.value) > parseInt(data.selectedSequence)
  );

  const onChangeMaxSelectedSequence = (event) => {
    setData({
      ...data,
      maxSelectedSequence: event.value,
    });
  };

  const rebuildShearData = (sequenceValue) => {
    //setShowchart(false)
    let data = [];
    const arraySize = 10;
    const maxValue = parseFloat(Math.max(...relativeViscoscity).toFixed(3));

    for (let i = 0; i < arraySize; i++) {
      if (arraySize - i === parseInt(sequenceValue)) {
        data.unshift(maxValue); // Coloca el valor máximo en la posición correcta
      } else {
        data.unshift(null); // Coloca un 0 en las demás posiciones
      }
    }
    data = data.reverse();

    return data;
  };

  useEffect(() => {
    if (!isObjectEmpty(result)) {
      setIsDisabled(true);
    }
  }, [result]);

  useEffect(() => {
    // Actualiza `data` para incluir el `header` actualizado
    setData((prevData) => ({
      ...prevData,
      header,
    }));
  }, [header]);

  const onChangeHandler = (e) => {
    const { id, value } = e.target;

    if (id.includes(".")) {
      const [outerKey, innerKey] = id.split(".");

      setData((prevData) => ({
        ...prevData,
        [outerKey]: {
          ...prevData[outerKey],
          [innerKey]: value,
        },
      }));
    } else {
      setData((prevData) => ({
        ...prevData,
        [id]: value,
      }));
    }
  };

  const fillTable = async () => {
    try {
      const newData = data?.table?.map((item) => {
        // Calcula y devuelve un nuevo objeto para cada elemento
        return {
          ...item,
          maxPressPpsi: (data.intRatio * item.maxPress).toString(),
          flowRate:
            data.header?.uom !== "INGLES"
              ? (
                  (parseFloat(item.speed) * parseFloat(data.screwArea)) /
                  1000
                ).toString()
              : (
                  parseFloat(item.speed) * parseFloat(data.screwArea)
                ).toString(),
          realSpeed: (
            (parseFloat(data.shotSizePosition) +
              parseFloat(data.decompressionSize) -
              parseFloat(item.vpTransfer)) /
            parseFloat(item.fillTime)
          ).toString(),
          efficence: (
            (((parseFloat(data.shotSizePosition) +
              parseFloat(data.decompressionSize) -
              parseFloat(item.vpTransfer)) /
              parseFloat(item.fillTime)) *
              100) /
            parseFloat(item.speed)
          ).toString(),
          relVisc: parseFloat(item.fillTime * (data.intRatio * item.maxPress))
            .toFixed(3)
            .toString(),
          shearRate: (1 / parseFloat(item.fillTime)).toString(),
          speed: item.speed.toString(),
        };
      });

      // Actualiza el estado con la tabla nueva
      setData((prevData) => ({
        ...prevData,
        table: newData, // Asegúrate de que `newData` sea la nueva tabla
      }));
      //setShowchart(false);
      setIsTableFormComplete(true);

      const renewData = newData
        .slice(0)
        .reverse()
        .map((item) => {
          return parseInt(item.maxPressPpsi).toFixed(2);
        });
      const data2 = newData
        .slice(0)
        .reverse()
        .map((item) => {
          return parseFloat(item.shearRate).toFixed(3);
        });
      const data3 = newData
        .slice(0)
        .reverse()
        .map((item) => {
          return parseInt(item.relVisc);
        });
      setDataChart(renewData);
      setShearDataChart(data2);
      setRelativeViscoscity(data3);
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeSequenceHandler = (e) => {
    try {
      setData({
        ...data,
        selectedSequence: e.value.toString(),
      });
    } catch (e) {
      console.log(e);
    }
  };

  const lineChartOptionsMatrix = {
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
    },
    chart: {
      height: 350,
      type: "line",
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      show: false,
      enabled: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "datetime",
      categories: shearDataChart,
      labels: {
        style: {
          colors: textColor,
          fontSize: "12px",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: [
      {
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: false,
          color: textColor,
        },
        labels: {
          show: true,
          style: {
            colors: textColor,
            fontSize: "12px",
          },
        },
        title: {
          show: false,
          text: "",
          style: {
            color: "#008FFB",
          },
        },
        tooltip: {
          enabled: false,
          show: false,
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 0,
        borderRadiusApplication: "around",
        borderRadiusWhenStacked: "last",
        columnWidth: "5%",
      },
    },
    legend: {
      show: false,
      color: "white",
    },
    grid: {
      show: false,
      strokeDashArray: 0,
      borderColor: "#FEB019",
    },
    colors: ["#053ecc", "#008FFB", "#00FF00", "#00FF00"],
  };

  const lineChartDataMatrix = [
    {
      name: "Presión Máxima Ppsi",
      type: "line",
      data: dataChart,
    },
    {
      name: "Presión Máxima Ppsi",
      type: "line",
      data: relativeViscoscity,
    },
    {
      name: "Presión Máxima Ppsi",
      type: "bar",
      data: shearDataBarChart,
    },
    {
      name: "Presión Máxima Ppsi",
      type: "bar",
      data: shearDataBarChart2,
    },
  ];

  const onSubmitHandler = async () => {
    setIsLoading(true);

    try {
      const restOperation = post({
        path: "/viscosity-study-individual",
        apiName: "viscoscityStudy",
        options: {
          body: {
            ...data,
          },
        },
      });

      const rest = await restOperation.response;
      const body = await rest.body.json();
      const updatedTable = updateTableSpeeds(
        data.table,
        body.speedIntervalUnit
      ); // Asegúrate de que esto es correcto

      await setData({ ...data, ...body, table: updatedTable });
      setShowDataTable(true);
    } catch (error) {
      console.log(error);
      const e = error.message ? error.message : error;
      showErrorAlert(e);
    }
    setIsLoading(false);
  };

  const onChangeTable = (e) => {
    const { name, value } = e.target;
    const [outerKey, index, innerKey] = name.split(".");

    if (innerKey) {
      // Manejar cambio para propiedades dentro de arrays (ej. data.table[0].speed)
      setData((prevData) => ({
        ...prevData,
        [outerKey]: prevData[outerKey].map((item, idx) =>
          idx.toString() === index ? { ...item, [innerKey]: value } : item
        ),
      }));
    } else {
      // Manejar cambio para propiedades de nivel superior
      setData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const onFinishHandler = async () => {
    setIsLoading(true);
    const image = await createImages(chartRef);
    const res = await uploadData({
      key: `check-valve-individual/${Date.now()}.png`,
      data: image,
    }).result;
    console.log(data)

    try {
      const response = await DataStore.save(
        new ViscosityStudy({
          ...data,
          chartImage: res.key,
          userID: user.id,
        })
      );

      let text = "";
      let icon = "success";

      if (parseFloat(data?.screwDiameter) > parseFloat(data?.pistonDiameter)) {
        text +=
          "<li>El diámetro del tornillo, debe de ser menor que el diámetro del pistón</li>";
        icon = "warning";
      }
      if (parseFloat(data?.screwDiameter) > parseFloat(data?.pistonDiameter)) {
        text +=
          "<li>El diámetro del Pistón debe de ser igual o mayor que el diametro del tornillo</li>";
        icon = "warning";
      }
      if (parseFloat(data?.realMeltTemp) < 0) {
        text +=
          "<li>La temperatura de masa real debe de ser mayor a 0 (Cero)</li>";
        icon = "warning";
      }
      if (parseFloat(data?.maxInjectionSpeed) < 0) {
        text +=
          "<li>La Máxima velocidad de inyeccion debe de ser mayor a 0 (Cero)</li>";
        icon = "warning";
      }
      if (
        parseFloat(data?.shotSizePosition) <= parseFloat(data?.cutoffPosition)
      ) {
        text +=
          "El tamaño de disparo debe de ser mayor a Posición de Transferencia V/P</li>";
        icon = "warning";
      }
      if (
        parseFloat(data?.shotSizePosition) < parseFloat(data?.cutoffPosition)
      ) {
        text +=
          "La Posición de Transferencia V/P debe de ser menor al tamaño de disparo.</li>";
        icon = "warning";
      }
      if (parseFloat(data?.decompressionSize) <= 0) {
        text += "El Tamaño de Descompresión debe de ser mayor a 0 (Cero)</li>";
        icon = "warning";
      }
      if (
        parseFloat(data?.cushionPositionTheoric) >=
        parseFloat(data?.cutoffPosition)
      ) {
        text +=
          "La Posición Teórica de Colchón debe de ser menor al tamaño de disparo.</li>";
        icon = "warning";
      }
      if (parseFloat(data?.shotWeightFirstStage) <= 0) {
        text += "El Peso de Primera Etapa debe de ser mayor a 0 (Cero)</li>";
        icon = "warning";
      }
      if (parseFloat(data?.specificWeight) <= 0) {
        text += "El Peso Específico debe de ser mayor a 0 (Cero)</li>";
        icon = "warning";
      }
      if (parseFloat(data?.immMaxHPress) <= 0) {
        text +=
          "La Máxima Presión de Inyección debe de ser mayor a 0 (Cero)</li>";
        icon = "warning";
      }
      if (parseFloat(data?.maxMachineUse) > 100) {
        text += "El Uso Deseado de Máquina debe de ser menor a 100 (Cien)</li>";
        icon = "warning";
      }
      if (parseFloat(data?.processEfficence) > 100) {
        text += "La Eficiencia Requerida debe de ser menor a 100 (Cien)</li>";
        icon = "warning";
      }
      setResult(response);
      conditionalsAlert(icon, text);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    uom,
    toolName,
    resinName,
    productName,
    machineName,
    data,
    onChangeHandler,
    onSubmitHandler,
    isLoading,
    result,
    showDataTable,
    onChangeTable,
    isTableFormComplete,
    fillTable,
    showChart,
    lineChartOptionsMatrix,
    lineChartDataMatrix,
    onChangeSequenceHandler,
    maxSequenceOptions,
    onChangeMaxSelectedSequence,
    setShowDataTable,
    setShowchart,
    chartRef,
    onFinishHandler,
  };
};
