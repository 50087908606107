// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const PostState = {
  "PUBLISHED": "PUBLISHED",
  "DRAFT": "DRAFT",
  "ARCHIVED": "ARCHIVED"
};

const ReactionTypes = {
  "LIKE": "LIKE",
  "SURPRISED": "SURPRISED",
  "LOVE": "LOVE"
};

const PaymentTypes = {
  "STRIPE": "STRIPE"
};

const BillStatus = {
  "COMPLETE": "COMPLETE",
  "IN_PROGRESS": "IN_PROGRESS",
  "REJECTED": "REJECTED"
};

const SubcriptionTypes = {
  "BASICO": "BASICO",
  "AVANZADO": "AVANZADO",
  "TEAM": "TEAM",
  "BUSINESS": "BUSINESS",
  "TECNICAS_PLASTICAS": "TECNICAS_PLASTICAS"
};

const DryerType = {
  "AIR_DRYER": "AIR_DRYER",
  "DESICCANT_DRYER": "DESICCANT_DRYER"
};

const Uom = {
  "INTERNACIONAL": "INTERNACIONAL",
  "INGLES": "INGLES"
};

const MachineTypes = {
  "VERTICAL": "VERTICAL",
  "HORIZONTAL": "HORIZONTAL",
  "SHUTTLE": "SHUTTLE"
};

const YesOrNot = {
  "SI": "SI",
  "NO": "NO"
};

const GateForm = {
  "CIRCULAR": "CIRCULAR",
  "RECTANGULAR": "RECTANGULAR"
};

const ToolGrade = {
  "I": "I",
  "II": "II",
  "III": "III",
  "TIPO_I": "TIPO_I",
  "TIPO_II": "TIPO_II",
  "TIPO_III": "TIPO_III",
  "TIPO_101": "TIPO_101",
  "TIPO_102": "TIPO_102",
  "TIPO_103": "TIPO_103",
  "TIPO_104": "TIPO_104",
  "TIPO_105": "TIPO_105",
  "TIPO_401": "TIPO_401",
  "TIPO_402": "TIPO_402",
  "TIPO_403": "TIPO_403",
  "TIPO_404": "TIPO_404"
};

const { GeaTool, Comment, Post, PostCategory, LinearityStudy, ViscosityStudy, CheckValveRepeatibility, LossPressure, ShotSize, Melt3030, HooperCapacity, DryerSupply, ProjectedAreaAndTonnage, IntensificationRatio, SubscriptionPlans, Bill, User, ResinType, Project, Resin, Machine, Tool, Reaction, ViscosityStudyTable, LinearityStudyTable, CheckValveRepeatibilityTableResults, CheckValveRepeatibilityTable, MinMax, HeaderType } = initSchema(schema);

export {
  GeaTool,
  Comment,
  Post,
  PostCategory,
  LinearityStudy,
  ViscosityStudy,
  CheckValveRepeatibility,
  LossPressure,
  ShotSize,
  Melt3030,
  HooperCapacity,
  DryerSupply,
  ProjectedAreaAndTonnage,
  IntensificationRatio,
  SubscriptionPlans,
  Bill,
  User,
  ResinType,
  Project,
  Resin,
  Machine,
  Tool,
  PostState,
  ReactionTypes,
  PaymentTypes,
  BillStatus,
  SubcriptionTypes,
  DryerType,
  Uom,
  MachineTypes,
  YesOrNot,
  GateForm,
  ToolGrade,
  Reaction,
  ViscosityStudyTable,
  LinearityStudyTable,
  CheckValveRepeatibilityTableResults,
  CheckValveRepeatibilityTable,
  MinMax,
  HeaderType
};