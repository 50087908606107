import React from "react";
import { Flex, Grid, GridItem, Heading } from "@chakra-ui/react";
import IndividualHeader from "./IndividualHeader";
import { useHeaderHooks } from "../hooks/useHeaderHooks";
import GoBackButton from "./Buttons/GoBackButton";
import DescriptionAndInstructionAcordeon from "../../../../components/acordeon/DescriptionAndInstructionAcordeon";

const IndividualBase = ({ children, title, instructions, description }) => {
  const {
    onSubmitHandler,
    setName,
    isLoading,
    name,
    user,
    selectOptions,
    machineName,
    setResinName,
    productName,
    resinName,
    setMachineName,
    setProductName,
    toolName,
    setToolName,
    setUom,
    isDisabled,
    setIsDisabled,
  } = useHeaderHooks();

  const childrenWithSetIsDisabled = React.Children.map(children, (child) => {
    // Agrega la función setIsDisabled a cada hijo
    return React.cloneElement(child, { setIsDisabled });
  });
  return (
    <Flex direction="column" width={"100%"} alignItems={"center"}>
      <Grid templateColumns="repeat(6, 1fr)" gap={6}>
        <GridItem colSpan={3}>
          <Heading as={"h1"}>{title}</Heading>
        </GridItem>
        <GridItem />
        <GridItem />
        <GridItem>
          <GoBackButton />
        </GridItem>
      </Grid>
      <DescriptionAndInstructionAcordeon
        description={description}
        instructions={instructions}
      />
      <IndividualHeader
        isDisabled={isDisabled}
        onSubmitHandler={onSubmitHandler}
        user={user}
        name={name}
        isLoading={isLoading}
        productName={productName}
        resinName={resinName}
        setProductName={setProductName}
        selectOptions={selectOptions}
        machineName={machineName}
        setMachineName={setMachineName}
        setName={setName}
        setResinName={setResinName}
        setToolName={setToolName}
        setUom={setUom}
        toolName={toolName}
      />

      {childrenWithSetIsDisabled}
    </Flex>
  );
};

export default IndividualBase;
