import {
  hpsiToHbar,
  inMm,
  inSecMmsec,
  ozGr,
  ppsiToPbar,
  temp,
} from "../../../../../hooks/useUomTransform";

export const dataEntries1 = (data) => {
  console.log(data);

  const entries = [
    {
      label: "Peso de Disparo al 100%",
      value: data?.shotWeight100,
      type: "",
      addOn: ozGr(data?.header?.uom),
    },
    {
      label: "Diámetro de Tornillo",
      value: data?.screwDiameter,
      type: "",
      addOn: inMm(data?.header?.uom),
    },
    {
      label: "Posición de Disparo",
      value: data?.shotSizePosition,
      type: "",
      addOn: inMm(data?.header?.uom),
    },

    {
      label: "Posición de Transferencia V/P",
      value: data?.cutoffPosition,
      type: "",
      addOn: inMm(data?.header?.uom),
    },
    {
      label: "Máxima Velocidad de Inyección",
      value: data?.maxInjectionSpeed,
      type: "",
      addOn: inSecMmsec(data?.header?.uom),
    },
    {
      label: "Peso Específico",
      value: data?.specificWeight,
      type: "",
      addOn: ":1",
    },
    {
      label: "Forma de Compuerta",
      value: data?.gateForm === "circle" ? "Circulo" : "Rectangulo",
      type: "text",
      addOn: "",
    },

  ]

  if (data?.gateForm !== "circle") {
    entries.push({
      label: "Altura de Compuerta",
      value: data?.heightGate,
      type: "",
      addOn: inMm(data?.header?.uom),
    },)
  }
  entries.push({
    label: "Coeficiente de Corrección",
    value: data?.correctCoef,
    type: "temp",
    addOn: "%",
  },{
    label: "Tolerancia de Linealidad",
    value: data?.rangeLinearity,
    type: "temp",
    addOn: "%",
  },)
  return entries
};

export const dataEntries2 = (data) => {
  const entries = [
    {
      label: "Número de Cavidades",
      value: data?.numberOfCavities,
      type: "int",
      addOn: "pcs",
    },

    {
      label: "Diámetro de Pistón",
      value: data?.pistonDiameter,
      type: "",
      addOn: inMm(data?.header?.uom),
    },
    {
      label: "Tamaño de Descompresión",
      value: data?.decompressionSize,
      type: "",
      addOn: inMm(data?.header?.uom),
    },
    {
      label: "Posición Teórica de Colchón",
      value: data?.cushionPositionTheoric,
      type: "",
      addOn: inMm(data?.header?.uom),
    },
    {
      label: "Máxima Presión de Inyección",
      value: data?.immMaxHPress,
      type: "separated",
      addOn: hpsiToHbar(data?.header?.uom),
    },
    {
      label: "Peso de Cavidades 1a Etapa",
      value: data?.shotWeightFirstStage,
      type: "",
      addOn: ozGr(data?.header?.uom),
    },
  ];

  if (data?.gateForm === "circle") {
    entries.push({
      label: "Diámetro de Compuerta",
      value: data?.gateDiameter,
      type: "",
      addOn: inMm(data?.header?.uom),
    });
  } else {
    entries.push(
      {
        label: "Grosor de Compuerta",
        value: data?.widthGate,
        type: "",
        addOn: inMm(data?.header?.uom),
      },
      {
        label: "Profundidad de Compuerta",
        value: data?.depthGate,
        type: "",
        addOn: inMm(data?.header?.uom),
      }
    );
  }

  entries.push({
    label: "Máximo Shear Rate de Resina",
    value: data?.resinMaxShearRate,
    type: "separated",
    addOn: "",
  },{
    label: "Uso Deseado de Máquina",
    value: data?.maxMachineUse,
    type: "temp",
    addOn: "%",
  })
  return entries;
};
