import { Grid, Text } from "@chakra-ui/react";
import React from "react";
import { textColor } from "../../../utilities/variables";
import {
  hpsiToHbar,
  inCubeCmCube,
  inMm,
  inSecMmsec,
  ozGr,
  ppsiToPbar,
} from "../../../../../individual/hooks/useUomTransform";

const ViscosityStudyHeader = ({ uom }) => {
  const thFontSize = "10px";
  return (
    <Grid
      templateColumns="repeat(12, 1fr)"
      gap={1}
      color={textColor}
      justifyItems={"center"}
    >
      <Text
        fontSize={thFontSize}
        fontFamily="Plus Jakarta Display"
        borderColor="#56577A"
        fontWeight={"bold"}
      >
        SECUENCIA DE DISPARO
      </Text>
      <Text
        fontSize={thFontSize}
        fontFamily="Plus Jakarta Display"
        borderColor="#56577A"
        fontWeight={"bold"}
      >
        {`VELOCIDAD DE INTERVALOS (${inSecMmsec(uom)})`}
      </Text>
      <Text
        fontSize={thFontSize}
        fontFamily="Plus Jakarta Display"
        borderColor="#56577A"
        fontWeight={"bold"}
      >
        TIEMPO DE LLENADO (seg)
      </Text>
      <Text
        fontSize={thFontSize}
        fontFamily="Plus Jakarta Display"
        borderColor="#56577A"
        fontWeight={"bold"}
      >
        {`PRESION MAXIMA (${hpsiToHbar(uom)})`}
      </Text>
      <Text
        fontSize={thFontSize}
        fontFamily="Plus Jakarta Display"
        borderColor="#56577A"
        fontWeight={"bold"}
      >
        {`PESO DE DISPARO (${ozGr(uom)})`}
      </Text>
      <Text
        fontSize={thFontSize}
        fontFamily="Plus Jakarta Display"
        borderColor="#56577A"
        fontWeight={"bold"}
      >
        {`POSICION DE CORTE V/P (${inMm(uom)})`}
      </Text>
      <Text
        fontSize={thFontSize}
        fontFamily="Plus Jakarta Display"
        borderColor="#56577A"
        fontWeight={"bold"}
      >
        {`PRESION MAXIMA (${ppsiToPbar(uom)})`}
      </Text>
      <Text
        fontSize={thFontSize}
        fontFamily="Plus Jakarta Display"
        borderColor="#56577A"
        fontWeight={"bold"}
      >
        {`FLOW RATE (${inCubeCmCube(uom)})`}
      </Text>
      <Text
        fontSize={thFontSize}
        fontFamily="Plus Jakarta Display"
        borderColor="#56577A"
        fontWeight={"bold"}
      >
        {`VISCOSIDAD REAL DE MAQUINA (${inSecMmsec(uom)})`}
      </Text>
      <Text
        fontSize={thFontSize}
        fontFamily="Plus Jakarta Display"
        borderColor="#56577A"
        fontWeight={"bold"}
      >
        EFICIENCIA DE VELOCIDAD (%)
      </Text>
      <Text
        fontSize={thFontSize}
        fontFamily="Plus Jakarta Display"
        borderColor="#56577A"
        fontWeight={"bold"}
      >
        SHARE RATE (1/seg)
      </Text>
      <Text
        fontSize={thFontSize}
        fontFamily="Plus Jakarta Display"
        borderColor="#56577A"
        fontWeight={"bold"}
      >
        VISCOSIDAD RELATIVA
      </Text>
    </Grid>
  );
};

export default ViscosityStudyHeader;
