import {
  inCubeMmCube,
  inMm,
  inSecMmsec,
  inSquareMmSquare,
  ppsiToPbar,
} from "../../../hooks/useUomTransform";

export const dataResults = (data) => [
  {
    label: "Tamaño Total de Disparo",
    value: data?.overallShotSize,
    type: "weight",
    addOn: inMm(data?.header?.uom),
  },
  {
    label: "Área de Tornillo",
    value: data?.screwArea,
    addOn: inSquareMmSquare(data?.header?.uom),
    type: "weight",
  },
  {
    label: "Área de Pistón",
    value: data?.pistonArea,
    addOn: inMm(data?.header?.uom),
    type: "weight",
  },
  {
    label: "Razón de Intensificación",
    value: data?.intRatio,
    addOn: ":1",
    type: "temp",
  },
  {
    label: "Volumen de Disparo",
    value: data?.shotVolume,
    addOn: inCubeMmCube(data?.header?.uom),
    type: "weight",
  },
  {
    label: "Máxima Presión Plástica",
    value: data?.maxPlasticPressure,
    addOn: ppsiToPbar(data?.header?.uom),
    type: "pressure",
  },
  {
    label: "MAX Presión Requerida",
    value: data?.maxPpsiUsed,
    type: "pressure",
    addOn: ppsiToPbar(data?.header?.uom),
  },
  {
    label: "Intervalos de Velocidad",
    value: data?.speedIntervalUnit,
    type: "weight",
    addOn: inSecMmsec(data?.header?.uom),
  },
];
