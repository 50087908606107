export const areTablePropertiesComplete = (table) => {
  // Propiedades requeridas para cada elemento del arreglo table
  const requiredProperties = ['sequence', 'speed', 'fillTime', 'maxPress', 'vpTransfer', 'cavityWeight'];

  // Verificar si todos los elementos del arreglo cumplen con la condición
  return table.every(item =>
    requiredProperties.every(prop =>
      item[prop] !== undefined && item[prop] !== ''
    )
  );
};

