import {hpsiToHbar, inMm, inSecMmsec, ozGr, ppsiToPbar, temp} from "../../../../../hooks/useUomTransform";

export const dataEntries1 = (data) => {
  console.log(data)
  return [
    {
      label: "Diametro del tornillo",
      value: data?.screwDiameter,
      type: "",
      addOn: inMm(data?.header?.uom)
    },
    {
      label: "Temperatura de Masa Real",
      value: data?.realMeltTemp,
      type: "temp",
      addOn: temp(data?.header?.uom)
    },
    {
      label: "Tamaño de Disparo",
      value: data?.shotSizePosition,
      type: "",
      addOn: inMm(data?.header?.uom)
    },
    {
      label: "Tamaño de Descompresión",
      value: data?.decompressionSize,
      type: "",
      addOn: inMm(data?.header?.uom)
    },
    {
      label: "Peso de Primera Etapa",
      value: data?.shotWeightFirstStage,
      type: "",
      addOn: ozGr(data?.header?.uom)
    },
    {
      label: "Máxima Presión de Inyección",
      value: data?.immMaxHPress,
      type: "int",
      addOn: hpsiToHbar(data?.header?.uom)
    },
    {
      label: "Eficiencia Requerida",
      value: data?.processEfficence,
      type: "temp",
      addOn: "%"
    },

  ]
}

export const dataEntries2 = data => {
  return [
    {
      label: "Diametro de Piston",
      value: data?.pistonDiameter,
      type: "",
      addOn: inMm(data?.header?.uom)
    },

    {
      label: "Máxima Velocidad de Inyección",
      value: data?.maxInjectionSpeed,
      type: "",
      addOn: inSecMmsec(data?.header?.uom)
    },
    {
      label: "Posición de Transferencia V/P",
      value: data?.cutoffPosition,
      type: "",
      addOn: inMm(data?.header?.uom)
    },
    {
      label: "Posición Teórica de Colchón",
      value: data?.cushionPositionTheoric,
      type: "",
      addOn: inMm(data?.header?.uom)
    },
    {
      label: "Peso Específico",
      value: data?.specificWeight,
      type: "",
      addOn: inMm(data?.header?.uom)
    },
    {
      label: "Uso Deseado de Máquina",
      value: data?.maxMachineUse,
      type: "temp",
      addOn: "%"
    },
  ]
}
