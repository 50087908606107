import React from "react";
import { colors } from "../../../theme/colors";
import CardHeader from "../../../components/Card/CardHeader";
import { cardBodyStyles, cardHeaderStyles } from "../../../theme/styles";
import { Flex, Grid } from "@chakra-ui/react";
import Card from "../../../components/Card/Card";
import HorizontalDivider from "../../../components/dividers/HorizontalDivider";
import CardBody from "../../../components/Card/CardBody";
import OverViewCard from "../project-overview/components/Cards/OverViewCard";
import { useHistory } from "react-router-dom";
import intensificationRatioImage from "../../../assets/img/razon_Intensificacion.png";
import projectedAreaImage from "../../../assets/img/area_Proyectada.png";
import dryerSupplyImage from "../../../assets/img/abastecimiento_de_secador.png";
import hooperCapacityImage from "../../../assets/img/capacidad_de_Tolva.png";
import melt3030Image from "../../../assets/img/melt_30_30.png";
import shootSize from "../../../assets/img/tamaño_disparo.png";
import pressureLossImage from "../../../assets/img/perdida_de_presion.png";
import vcheckRepeteability from "../../../assets/img/repetibilidad_v_check.png";
import viscosityImage from "../../../assets/img/Viscosidad.png";
import linearityImage from "../../../assets/img/linealidad-1.png";
import cavityBalanceImage from "../../../assets/img/Balance_de_Cavidades.png";
import sustainigPressureImage from "../../../assets/img/presion_Sostenimiento.png";
import holdTimeImage from "../../../assets/img/Tiempo_Sostenimiento.png";
import coolingTimeImage from "../../../assets/img/tiempo_Enfriamiento.png";
import enduranceTimeImage from "../../../assets/img/tiempo_Residencia.png";
import windowProcess from "../../../assets/img/ventana_de_Proceso.png";
import HelmetComponent from "../../../components/HelmetComponent";
import {
  metaDescription,
  metaKeywords,
  metaTitle,
} from "./utilities/metaVariables";
const IndividualOverView = () => {
  const history = useHistory();

  return (
    <>
      <HelmetComponent
        description={metaDescription}
        title={metaTitle}
        keywords={metaKeywords}
      />
      <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
        <Card bg={colors.bgGradientVertical180}>
          <CardHeader {...cardHeaderStyles}>Pruebas Individuales</CardHeader>
          <HorizontalDivider mt={5} />
          <CardBody {...cardBodyStyles}>
            <Flex
              direction={"column"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Grid
                justifyContent={"space-between"}
                {...cardBodyStyles}
                w={"100%"}
                templateColumns="repeat(4, 8fr)"
                gap={6}
              >
                <OverViewCard
                  overview={true}
                  logo={intensificationRatioImage}
                  phase={"1"}
                  compare={"1"}
                  headerTitle={"Razon de Intensificacion"}
                  onEditClick={() =>
                    history.push(
                      `/admin/projects/individual/intensification-ratio`
                    )
                  }
                  onEyeClick={ () => history.push(
                    `/admin/projects/individual/intensification-ratio-index`
                  )}
                />
                <OverViewCard
                  overview={true}
                  logo={projectedAreaImage}
                  phase={"1"}
                  compare={"1"}
                  headerTitle={"Area Proyectada y Tonelaje"}
                  onEditClick={() =>
                    history.push(`/admin/projects/individual/projected-area`)
                  }
                  onEyeClick={ () => history.push(
                    `/admin/projects/individual/projected-area-index`
                  )}
                />
                <OverViewCard
                  logo={dryerSupplyImage}
                  overview={true}
                  phase={2}
                  compare={"2"}
                  headerTitle={"Absatecimiento de Secador"}
                  onEditClick={() =>
                    history.push(`/admin/projects/individual/dryer-supply`)
                  }
                  onEyeClick={() =>
                    history.push(`/admin/projects/individual/dryer-supply-index`)}
                />
                <OverViewCard
                  overview={true}
                  logo={hooperCapacityImage}
                  phase={3}
                  compare={"3"}
                  headerTitle={"Capacidad de Tolva"}
                  onEditClick={() =>
                    history.push(`/admin/projects/individual/hooper-capacity`)
                  }
                  onEyeClick={() => history.push(`/admin/projects/individual/hooper-capacity-index`)}
                />
              </Grid>
              <HorizontalDivider mt={5} />
              <Grid
                {...cardBodyStyles}
                w={"100%"}
                templateColumns="repeat(4, 6fr)"
                gap={6}
              >
                <OverViewCard
                  overview={true}
                  logo={melt3030Image}
                  phase={4}
                  compare={"4"}
                  headerTitle={"Melt 30/30"}
                  onEditClick={() =>
                    history.push(`/admin/projects/individual/melt-3030`)
                  }
                  onEyeClick={() => history.push(`/admin/projects/individual/melt-3030-index`)}
                />
                <OverViewCard
                  overview={true}
                  logo={shootSize}
                  phase={5}
                  compare={"5"}
                  headerTitle={"Tamaño de Disparo"}
                  onEditClick={() =>
                    history.push(`/admin/projects/individual/shot-size`)
                  }
                  onEyeClick={() => history.push(`/admin/projects/individual/shot-size-index`)}
                />
                <OverViewCard
                  overview={true}
                  logo={pressureLossImage}
                  phase={6}
                  compare={"6"}
                  headerTitle={"Analisis de Perdida de Presión"}
                  onEditClick={() =>
                    history.push(`/admin/projects/individual/loss-pressure`)
                  }
                  onEyeClick={() => history.push(`/admin/projects/individual/loss-pressure-index`)}
                />
                <OverViewCard
                  overview={true}
                  logo={vcheckRepeteability}
                  phase={7}
                  compare={"7"}
                  headerTitle={"Repetibilidad de V-Check"}
                  onEditClick={() =>
                    history.push(
                      `/admin/projects/individual/check-valve-repeatibility`
                    )
                  }
                  //check-valve-repeatibility
                  onEyeClick={() =>
                    history.push(
                      `/admin/projects/individual/check-valve-repeatibility-index`
                    )
                  }
                />
              </Grid>
              <HorizontalDivider mt={5} />
              <Grid
                {...cardBodyStyles}
                w={"100%"}
                templateColumns="repeat(4, 1fr)"
                gap={6}
              >
                <OverViewCard
                  overview={true}
                  logo={viscosityImage}
                  phase={8}
                  compare={"8"}
                  headerTitle={"Estudio de Viscosidad"}
                  onEditClick={() =>
                    history.push(`/admin/projects/individual/viscosity-study`)
                  }
                  onEyeClick={() =>  history.push(
                    `/admin/projects/individual/viscosity-study-index`
                  )}
                />
                <OverViewCard
                  logo={linearityImage}
                  overview={true}
                  phase={9}
                  compare={"9"}
                  headerTitle={"Estudio de Linealidad y reología"}
                  onEditClick={() =>
                    history.push(`/admin/projects/individual/linearity-study`)
                  }
                  onEyeClick={() =>  history.push(
                    `/admin/projects/individual/linearity-study-index`
                  )}                />
                <OverViewCard
                  logo={cavityBalanceImage}
                  overview={true}
                  phase={7}
                  compare={"10"}
                  headerTitle={"Balance de Cavidades"}
                  onEditClick={() =>
                    history.push(`/admin/edit-project/cavity-balance/${id}`)
                  }
                  onEyeClick={() => console.log("eye")}
                />
                <OverViewCard
                  logo={sustainigPressureImage}
                  overview={true}
                  phase={7}
                  compare={"11"}
                  headerTitle={"Presión de Sostenimiento"}
                  onEditClick={() =>
                    history.push(
                      `/admin/edit-project/sustaining-pressure/${id}`
                    )
                  }
                  onEyeClick={() => console.log("eye")}
                />
              </Grid>
              <HorizontalDivider mt={5} />
              <Grid
                {...cardBodyStyles}
                w={"100%"}
                templateColumns="repeat(4, 1fr)"
                gap={6}
              >
                <OverViewCard
                  logo={holdTimeImage}
                  overview={true}
                  phase={7}
                  compare={"12"}
                  headerTitle={"Sellado de Compuerta"}
                  onEditClick={() =>
                    history.push(`/admin/edit-project/gate-sealing/${id}`)
                  }
                  onEyeClick={() => console.log("eye")}
                />
                <OverViewCard
                  logo={coolingTimeImage}
                  overview={true}
                  phase={7}
                  compare={"13"}
                  headerTitle={"Tiempo de Enfriamiento"}
                  onEditClick={() =>
                    history.push(`/admin/edit-project/cooling-time/${id}`)
                  }
                  onEyeClick={() => console.log("eye")}
                />
                <OverViewCard
                  logo={enduranceTimeImage}
                  overview={true}
                  phase={7}
                  compare={"14"}
                  headerTitle={"Tiempo de Residencia"}
                  onEditClick={() =>
                    history.push(`/admin/edit-project/endurance-time/${id}`)
                  }
                  onEyeClick={() => console.log("eye")}
                />
                <OverViewCard
                  logo={windowProcess}
                  overview={true}
                  phase={7}
                  compare={"15"}
                  headerTitle={"Ventana de Proceso"}
                  onEditClick={() =>
                    history.push(`/admin/edit-project/window-process/${id}`)
                  }
                  onEyeClick={() => console.log("eye")}
                />
              </Grid>
            </Flex>
          </CardBody>
        </Card>
      </Flex>
    </>
  );
};

export default IndividualOverView;
